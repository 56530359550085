<template>
    <pharmacy-layout>
        <v-container>
            <v-row>
                <v-col class="mt-8">
                    <h1 class="display-1">
                        {{ $t('events.verticalization') }}
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 mt-0">
                    <hr class="heading-divider">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-form ref="form">
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3"
                            >
                                <v-autocomplete
                                    v-if="parties.length > 0"
                                    :rules="[ruleRequired]"
                                    :items="parties"
                                    v-model="partyId"
                                    :label="$t('fields.pharmacy')"
                                    clearable
                                    @click:clear="partyId = null"
                                    item-value="id"
                                />
                                <v-text-field
                                    v-else
                                    v-model="partyName"
                                    :rules="[ruleRequired]"
                                    :label="$t('fields.pharmacy')"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3"
                            >
                                <v-text-field
                                    v-model="numAttendees"
                                    :rules="[ruleRequired, ruleGt(0)]"
                                    type="number"
                                    :label="$t('fields.num_attendees')"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3"
                            >
                                <v-text-field
                                    v-model="email"
                                    type="email"
                                    :rules="[ruleEmail]"
                                    :label="$t('fields.email')"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                xl="3"
                            >
                                <v-text-field
                                    v-model="phone"
                                    type="tel"
                                    :rules="[rulePhone]"
                                    :label="$t('fields.phone')"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="text-caption text-justify">
                                <p> {{ $t('fields.contact_legal') }} </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="mb-4 text-right">
                                <v-btn
                                    :loading="loading"
                                    dark
                                    large
                                    color="xf"
                                    @click="confirmAttendance"
                                >
                                    {{ $t('fields.confirm_attendance') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </pharmacy-layout>
</template>

<script>
import rules from '@/mixins/rules'
import { mapGetters } from 'vuex'

export default {
    name: 'Verticalization',
    mixins: [rules],
    data () {
        return {
            parties: [],
            confirmed: false,
            partyName: null,
            partyId: null,
            numAttendees: 1,
            email: '',
            phone: '',
        }
    },
    methods: {
        getDataFromApi () {
            this.$store.dispatch('loading/loading', true)

            this.getPharmacies().then((parties) => {
                this.parties = parties
            }).catch(error => {
                this.axiosCatch(error)
            }).finally(() => this.$store.dispatch('loading/loading', false))
        },
        getPharmacies () {
            return axios.get(`${this.apiUrl}/api/public/pharmacies`).then((response) => {
                return response.data.data.map(i => {
                    return {
                        id: i.id,
                        text: i.name
                    }
                })
            }).catch((error) => {
                return Promise.reject(error)
            })
        },
        confirmAttendance () {
            if (this.$refs.form.validate() && !this.loading) {
                this.$store.dispatch('loading/loading', true)
                axios.post(`${this.apiUrl}/api/public/events/verticalization`, {
                    party_id: this.partyId,
                    party_name: this.partyName,
                    num_attendees: this.numAttendees,
                    email: this.email,
                    phone: this.phone,
                }).then(() => {
                    this.alert(this.$i18n.translate('success.sent'), 'success')
                    this.$refs.form.reset()
                    this.$emit('confirmedAttendance')
                }).catch(error => {
                    this.axiosCatch(error)
                }).finally(() => this.$store.dispatch('loading/loading', false))
            }
        },
    },
    computed: {
        ...mapGetters({
            loading: 'loading/loading'
        }),
    },
    mounted () {
        this.getDataFromApi()
    },
}
</script>
