import Vue from 'vue';
import WelcomeLayout from '@/layouts/WelcomeLayout';
import MainLayout from '@/layouts/MainLayout';
import CustomerLayout from '@/layouts/CustomerLayout';
import PharmacyLayout from '@/layouts/PharmacyLayout';
import ContentsLayout from '@/layouts/ContentsLayout';

Vue.component('welcome-layout', WelcomeLayout);
Vue.component('main-layout', MainLayout);
Vue.component('customer-layout', CustomerLayout);
Vue.component('pharmacy-layout', PharmacyLayout);
Vue.component('contents-layout', ContentsLayout);
