import Vue from 'vue';
import vuexI18n from 'vuex-i18n';
import store from "@/store";
import Locales from "./locales";
import moment from "moment";

Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('ca', Locales.ca);
Vue.i18n.add('es', Locales.es);

let defaultLanguage = 'ca';
let localeStored = localStorage.getItem('locale');
let localeBrowser = navigator.language;
let hostname = window.location.hostname;
let brand = null;
if(hostname.search('xarxafarma') !== -1){
    brand = 'xarxa';
} else if(hostname.search('neoxfarma') !== -1){
    brand = 'neox';
}

if (localeStored) {
    Vue.i18n.set(localeStored);
} else if (localeBrowser === 'es-ES') {
    Vue.i18n.set('es');
} else if (localeBrowser === 'ca') {
    Vue.i18n.set('ca');
} else if (brand === 'xarxa') {
    Vue.i18n.set('ca');
} else if (brand === 'neox') {
    Vue.i18n.set('es');
} else {
    Vue.i18n.set(defaultLanguage);
}

moment.locale(Vue.i18n.locale());
