<template>
    <div>
        <thumbnail-post
            v-for="(post, i) in posts"
            :key="i"
            :post="post"
            :skeleton="skeleton"
            :show-title="showTitle"
            :show-image="showImage"
            :show-excerpt="showExcerpt"
            :show-category="showCategory"
            class="mb-6"
        />
    </div>
</template>

<script>
import ThumbnailPost from '@/components/contents/ThumbnailPost'

export default {
    components: {
        ThumbnailPost,
    },
    props: {
        posts: {
            type: Array,
            default () {
                return []
            }
        },
        skeleton: {
            type: Boolean,
            default: false,
        },
        showImage: {
            type: Boolean,
            default: true
        },
        showCategory: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showExcerpt: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped>

</style>
