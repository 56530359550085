<template>
    <header>
        <p
            v-if="post.pretitle"
            class="text-caption grey--text text--darken-1 my-0 text-uppercase"
            v-html="post.pretitle"
        />
        <h1
            v-html="post.title.rendered"
            :class="{'text-h5': excerpt, 'text-h3': !excerpt, 'my-0': excerpt}"
        />
        <p
            v-if="post.subtitle"
            class="text-h5 mt-3"
            v-html="post.subtitle"
        />
    </header>
</template>

<script>
export default {
    name: 'PostTitle',
    props: {
        post: {
            type: Object,
            default () {
                return {}
            }
        },
        excerpt: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
