<template>
    <pharmacy-layout>
        <services-external-block color="grey"></services-external-block>
        <v-img
            position="bottom center"
            :src="banner"
        ></v-img>
        <v-container fluid class="pharmacy-contact">
            <home-pharmacy-contact-block></home-pharmacy-contact-block>
        </v-container>
    </pharmacy-layout>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: this.$i18n.translate('menu.contact')
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
        banner() {
            return require(`@/assets/img/home/${ this.locale }/contact.jpg`);
        },
    },
}
</script>

<style scoped>

</style>
