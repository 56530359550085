import Vue from 'vue';
import axios from "axios";
import moment from "moment";
import lodash from "lodash";

require('moment/locale/ca');
require('moment/locale/es');

window.axios = axios;
window.moment = moment;
window._ = lodash;

import '@/lang';
import '@/layouts';
import '@/components';
import '@/blocks';
import '@/filters';

import '@/plugins/analytics';
import '@/plugins/flipbook';
import '@/plugins/smoothPicker';
import '@/plugins/gmaps';
import '@/plugins/meta';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';
import App from '@/components/App';

import global from '@/mixins/global';

Vue.mixin(global);

new Vue({
    router,
    vuetify,
    store,
    ...App,
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app');

