<template>
    <main-layout
        :header="header"
        :footer="footer"
        :pharmacy="pharmacy"
        :absolute="absolute"
        :gotop="gotop"
        :links="linksComputed"
    >
        <slot></slot>
    </main-layout>
</template>

<script>
export default {
    props: {
        header: {
            type: Boolean,
            default: true,
        },
        footer: {
            type: Boolean,
            default: true,
        },
        pharmacy: {
            type: Boolean,
            default: false,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        gotop: {
            type: Boolean,
            default: true,
        },
        links: {
            type: Array,
            default () {
                return []
            }
        }
    },
    computed: {
        linksComputed () {
            if (this.links.length > 0) {
                return this.links
            } else {
                return this.defaultLinks
            }
        },
        defaultLinks () {
            return [
                {
                    id: 'home',
                    translate: 'menu.home',
                    to: { 'name': 'home' },
                },
                {
                    id: 'card',
                    translate: 'menu.card',
                    to: { 'name': 'card' },
                },
                {
                    id: 'contents',
                    translate: 'menu.contents',
                    to: { 'name': 'contents', params: { locale: this.$i18n.locale() } },
                },
                {
                    id: 'map',
                    translate: 'menu.map',
                    to: { 'name': 'map' },
                },
                {
                    id: 'contact',
                    translate: 'menu.contact',
                    to: { 'name': 'contact' },
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>
