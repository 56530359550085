<template>
    <div
        class="grey lighten-3"
    >
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    md="9"
                    xl="10"
                >
                    <v-img
                        position="bottom left"
                        max-height="73px"
                        contain
                        :src="banner"
                    />
                </v-col>
                <v-col
                    cols="3"
                    xl="2"
                    class="hidden-sm-and-down d-md-flex"
                >
                    <contents-search-component
                        class="align-self-center"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    computed: {
        blogLocale () {
            return this.$i18n.locale()
        },
        banner () {
            return require(`@/assets/img/home/${this.blogLocale}/banner-2.svg`)
        },
    },
}
</script>
