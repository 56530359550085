import Vue from 'vue';
import BannerBlock from '@/blocks/BannerBlock';
import LeftBlock from '@/blocks/LeftBlock';
import RightBlock from '@/blocks/RightBlock';
import HomeCustomerCommitedBlock from '@/blocks/customer/HomeCustomerCommitedBlock';
import HomeCustomerCardBlock from '@/blocks/customer/HomeCustomerCardBlock';
import HomeCustomerMapBlock from '@/blocks/customer/HomeCustomerMapBlock';
import HomeCustomerServicesBlock from '@/blocks/customer/HomeCustomerServicesBlock';
import HomeCustomerServiceBlock from '@/blocks/customer/HomeCustomerServiceBlock';
import HomeCustomerContactBlock from '@/blocks/customer/HomeCustomerContactBlock';
import HomePharmacyStrengthBlock from "@/blocks/pharmacy/HomePharmacyStrengthBlock";
import HomePharmacyQualityBlock from "@/blocks/pharmacy/HomePharmacyQualityBlock";
import HomePharmacyValueBlock from "@/blocks/pharmacy/HomePharmacyValueBlock";
import HomePharmacyMapBlock from "@/blocks/pharmacy/HomePharmacyMapBlock";
import HomePharmacyServicesBlock from "@/blocks/pharmacy/HomePharmacyServicesBlock";
import HomePharmacyServicesIntroBlock from "@/blocks/pharmacy/HomePharmacyServicesIntroBlock";
import HomePharmacyDatesBlock from "@/blocks/pharmacy/HomePharmacyDatesBlock";
import HomePharmacyContactBlock from "@/blocks/pharmacy/HomePharmacyContactBlock";
import CardFreeBlock from "@/blocks/customer/CardFreeBlock";
import CardEarnBlock from "@/blocks/customer/CardEarnBlock";
import CardDiscountsBlock from "@/blocks/customer/CardDiscountsBlock";
import CardGiftsBlock from "@/blocks/customer/CardGiftsBlock";
import CardInfoBlock from "@/blocks/customer/CardInfoBlock";
import CardSubscribeBlock from "@/blocks/customer/CardSubscribeBlock";
import ServicesExternalBlock from "@/blocks/ServicesExternalBlock";

Vue.component('banner-block', BannerBlock);
Vue.component('left-block', LeftBlock);
Vue.component('right-block', RightBlock);
Vue.component('home-customer-commited-block', HomeCustomerCommitedBlock);
Vue.component('home-customer-card-block', HomeCustomerCardBlock);
Vue.component('home-customer-map-block', HomeCustomerMapBlock);
Vue.component('home-customer-services-block', HomeCustomerServicesBlock);
Vue.component('home-customer-service-block', HomeCustomerServiceBlock);
Vue.component('home-customer-contact-block', HomeCustomerContactBlock);
Vue.component('home-pharmacy-strength-block', HomePharmacyStrengthBlock);
Vue.component('home-pharmacy-quality-block', HomePharmacyQualityBlock);
Vue.component('home-pharmacy-value-block', HomePharmacyValueBlock);
Vue.component('home-pharmacy-map-block', HomePharmacyMapBlock);
Vue.component('home-pharmacy-services-block', HomePharmacyServicesBlock);
Vue.component('home-pharmacy-services-intro-block', HomePharmacyServicesIntroBlock);
Vue.component('home-pharmacy-dates-block', HomePharmacyDatesBlock);
Vue.component('home-pharmacy-contact-block', HomePharmacyContactBlock);
Vue.component('card-free-block', CardFreeBlock);
Vue.component('card-earn-block', CardEarnBlock);
Vue.component('card-discounts-block', CardDiscountsBlock);
Vue.component('card-gifts-block', CardGiftsBlock);
Vue.component('card-info-block', CardInfoBlock);
Vue.component('card-subscribe-block', CardSubscribeBlock);
Vue.component('services-external-block', ServicesExternalBlock);
