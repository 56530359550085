<template>
    <right-block :title="$t('home.pharmacy_quality')">
        <template v-slot:img>
            <v-img
                src="@/assets/img/home-pharmacy/quality.jpg"
                contain
            ></v-img>
        </template>
        <template v-slot:content>
            <v-row>
                <v-col>
                    <p v-html="$t('home.pharmacy_quality_content')"></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p
                        :key="i"
                        v-for="(file, i) in files"
                    >
                        <v-btn
                            text
                            :href="file.href"
                            target="_blank"
                        >
                            <v-icon color="xf">mdi-file-pdf</v-icon>
                            {{ file.filename }}
                        </v-btn>
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-img
                        src="@/assets/img/home/quality.png"
                        contain
                        width="400px"
                        class="d-inline-flex"
                    ></v-img>
                </v-col>
            </v-row>
        </template>
    </right-block>
</template>

<script>
export default {
    data() {
        return {
            files: [],
        }
    },
    methods: {
        loadQualityFiles() {
            this.files = require.context('@/assets/pdf/quality/', true, /.pdf$/)
                .keys()
                .sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                }))
                .filter(i => i.replace('./', '').split('/')[0] === this.locale)
                .map(i => {
                    return {
                        filename: i.replace('./', '').split('/')[1].replace('.pdf', ''),
                        href: require(`@/assets/pdf/quality/${ i.replace('./', '') }`),
                    }
                });
        }
    },
    watch: {
        locale() {
            this.loadQualityFiles();
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
    },
    mounted() {
        this.loadQualityFiles();
    }
}
</script>

<style scoped>

</style>
