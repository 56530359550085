const NO_CATEGORY = 1;
const PRESENT_CATEGORY = 2;

const blog = {
    data() {
        return {
            blogUrl: process.env.VUE_APP_BLOG,
            PRESENT_CATEGORY: PRESENT_CATEGORY,
        }
    },
    methods: {
        getDataFromApi() {
            // Override this method
        },
        getCategories() {
            const PER_PAGE = 100;
            const PAGE = 1;
            let route = `${ this.blogUrl }/${ this.blogLocale }/wp-json/wp/v2/categories?per_page=${ PER_PAGE }&page=${ PAGE }&exclude=${ NO_CATEGORY }`;
            return axios.get(route).then(response => {
                return response.data;
            }).catch(error => {
                return Promise.reject(error);
            });
        },
        getPosts(page, perPage, categories = null, search = null, exclude = null) {
            let route = `${ this.blogUrl }/${ this.blogLocale }/wp-json/wp/v2/posts?_embed=wp:featuredmedia,wp:term&per_page=${ perPage }&page=${ page }`;

            if (categories) {
                if (Array.isArray(categories)) {
                    categories = categories.join(',')
                }
                route += `&categories=${ categories }`;
            }
            if (search) {
                route += `&search=${ search }`;
            }
            if (exclude) {
                if (Array.isArray(exclude)) {
                    exclude = exclude.join(',')
                }
                route += `&categories_exclude=${ exclude }`;
            }

            return axios.get(route).then(response => {
                return response;
            }).catch(error => {
                return Promise.reject(error);
            });
        },
        getPost(id, headers = null) {
            const route = `${ this.blogUrl }/${ this.blogLocale }/wp-json/wp/v2/posts/${ id }?_embed`;
            return axios.get(route, headers).then(response => {
                return response.data;
            }).catch(error => {
                return Promise.reject(error);
            });
        },
        getPreviewPost(id, token) {
            const headers = {
                headers: { Authorization: `Bearer ${ token }` }
            }
            return this.getPost(id, headers)
        },
        getPresentPosts(number = 7) {
            return this.getPosts(1, number, PRESENT_CATEGORY);
        },
        getLatestPosts(number = 3) {
            return this.getPosts(1, number, null, null, PRESENT_CATEGORY);
        },
        getMostReadPosts(number = 4) {
            return this.getPosts(1, number, 8);
        },
        getAds(route) {
            return axios.get(route).then(response => {
                return response.data;
            }).catch(error => {
                return Promise.reject(error);
            });
        },
        getAdsHome() {
            const route = `${ this.blogUrl }/${ this.blogLocale }/wp-json/wp/v2/ads?home`;
            return this.getAds(route);
        },
        getAdsCategory(category) {
            const route = `${ this.blogUrl }/${ this.blogLocale }/wp-json/wp/v2/ads?category=${ category }`;
            return this.getAds(route);
        },
        filterAds(ads, type) {
            return ads.filter(i => i.size === type).map(i => {
                return {
                    img: i.media.source_url,
                    href: i.link
                }
            })
        },
        filterAdsVertical(ads) {
            return this.filterAds(ads, 'vertical')
        },
        filterAdsBillboard(ads) {
            return this.filterAds(ads, 'billboard')
        },
        filterAdsNetboard(ads) {
            return this.filterAds(ads, 'netboard')
        },
    },
    computed: {
        blogLocale() {
            return this.$i18n.locale()
        },
    },
}

export default blog;
