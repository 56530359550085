<template>
    <router-link :to="item.to" custom v-slot="{ navigate }">
        <v-hover
            v-slot="{ hover }"
        >
            <v-container fluid class="pa-0 pointer" @click="navigate">
                <v-row>
                    <v-col>
                        <v-card
                            flat
                            tile
                            :elevation="hover ? 16 : 2"
                        >
                            <v-sheet
                                v-if="item.info"
                                class="circle-info white--text text-center"
                                rounded="circle"
                                elevation="1"
                                height="115"
                                width="115"
                                color="xf"
                            >
                                <v-container fill-height fluid class="pa-0">
                                    <v-row align="center"
                                           justify="center"
                                           no-gutters
                                    >
                                        <v-col>
                                            <p class="pa-0 ma-0" v-html="item.info"></p>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-sheet>
                            <v-img :src="item.img"></v-img>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-center">
                        <h1>{{ item.title }}</h1>
                    </v-col>
                </v-row>
                <header-divider-component></header-divider-component>
                <v-row>
                    <v-col class="text-center">
                        {{ item.content }}
                    </v-col>
                </v-row>
            </v-container>
        </v-hover>
    </router-link>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default() {
                return {
                    img: null,
                    title: null,
                    content: null,
                    to: null,
                    info: null,
                }
            }
        }
    }
}
</script>

<style scoped>
.circle-info {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
}
</style>
