<template>
    <v-form ref="formSearch" @submit.prevent="search">
        <v-text-field
            :label="$t('actions.search')"
            hide-details
            filled
            v-model="text"
            single-line
            flat
            solo
            prepend-inner-icon="mdi-magnify"
            dense
            outlined
            rounded
            clearable
        ></v-text-field>
    </v-form>
</template>

<script>
export default {
    data () {
        return {
            text: this.$route.params.text,
        }
    },
    methods: {
        search () {
            if (this.text) {
                this.$router.push({ name: 'contents.search', params: { text: this.text } })
            }
        }
    }
}
</script>

<style scoped>
form {
    max-width: 300px;
}
</style>
