import Vue from 'vue';
import Vuex from "vuex";
import alert from './modules/alert';
import loading from './modules/loading';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        alert: alert,
        loading: loading,
        //i18n ja és afegit automàticament
    },
});
