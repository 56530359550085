<template>
    <div>
        <header-component
            v-if="header"
            :links="links"
            :pharmacy="pharmacy"
            :absolute="absolute"
        ></header-component>
        <v-main>
            <slot></slot>
        </v-main>
        <go-top-component v-if="gotop"></go-top-component>
        <footer-component v-if="footer" :pharmacy="pharmacy"></footer-component>
        <cookies-component></cookies-component>
    </div>
</template>

<script>
export default {
    props: {
        header: {
            type: Boolean,
            default: true,
        },
        footer: {
            type: Boolean,
            default: true,
        },
        pharmacy: {
            type: Boolean,
            default: false,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        gotop: {
            type: Boolean,
            default: true,
        },
        links: {
            type: Array,
            default() {
                return [];
            }
        }
    },
}
</script>

<style scoped>

</style>
