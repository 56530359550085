<template>
    <v-bottom-sheet :value="isOpen" ref="sheet" :retain-focus="false" hide-overlay persistent no-click-animation>
        <v-sheet elevation="16">
            <v-container fluid>
                <v-row align="center">
                    <v-col cols="12" md="6" lg="8" xl="9" class="text-justify">
                        <p>
                            {{ $t('cookies.intro') }}
                            <router-link :to="{name: 'cookies'}">
                                {{ $t('menu.cookies') }}
                            </router-link>
                        </p>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="3" class="text-center">
                        <v-btn block class="mx-1 my-2" dark @click="accept">
                            {{ $t('actions.accept') }}
                        </v-btn>
                        <v-btn block class="mx-1 my-2" dark outlined color="error" @click="decline">
                            {{ $t('actions.accept_cookies_only_essential') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-bottom-sheet>
</template>
<script>

import cookies from "@/mixins/cookies";

export default {
    watch: {
        isOpen() {
            this.$nextTick(() => {
                this.$refs.sheet.showScroll();
            });
        }
    },
    mixins: [cookies],
}
</script>

<style scoped>

</style>
