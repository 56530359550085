<template>
    <contents-layout
        :verticalBanners="verticalBanners"
        :billboardBanners="billboardBanners"
    >
        <contents-post-component :skeleton="skeleton" :post="post"></contents-post-component>
    </contents-layout>
</template>

<script>
import blog from '@/mixins/blog'
import utils from "@/mixins/utils";

export default {
    metaInfo() {
        return {
            title: this.metaTitle,
            meta: [
                {vmid: 'description', name: 'description', content: this.metaDescription},
                {vmid: 'og:title', name: 'og:title', content: this.metaTitle},
                {vmid: 'og:description', name: 'og:description', content: this.metaDescription},
                {vmid: 'og:url', name: 'og:url', content: window.location.href},
                {vmid: 'og:image', name: 'og:image', content: this.metaImage},
                {vmid: 'og:type', name: 'og:type', content: 'article'},
            ],
        }
    },
    data() {
        return {
            post: null,
            verticalBanners: [],
            billboardBanners: [],
            redirectTranslation: null,
            skeleton: true,
            t: window.location.href
        }
    },
    mixins: [blog, utils],
    mounted() {
        this.getDataFromApi()
    },
    beforeRouteUpdate(to, from, next) {
        if (to.path !== this.redirectTranslationRoute) {
            next({path: this.redirectTranslationRoute})
        } else {
            next()
        }
    },
    computed: {
        blogLocale() {
            return this.$i18n.locale()
        },
        redirectTranslationRoute() {
            let route = null
            if (this.redirectTranslation) {
                const url = new URL(this.redirectTranslation);
                route = url.pathname
            }
            return route
        },
        metaTitle() {
            return this.post ? this.decodeHtml(this.post.title.rendered) : null
        },
        metaDescription() {
            return this.post ? this.decodeHtml(this.post.excerpt.rendered) : null
        },
        metaImage() {
            if (
                this.post &&
                this.post._embedded &&
                this.post._embedded['wp:featuredmedia'] &&
                this.post._embedded['wp:featuredmedia'][0] &&
                this.post._embedded['wp:featuredmedia'][0].source_url
            ) {
                return this.post._embedded['wp:featuredmedia'][0].source_url;
            }
            return null;

        },

    },
    methods: {
        getDataFromApi() {
            this.getPost(this.$route.params.post).then(response => {
                this.post = response
                const ads = response['_embedded']?.['infoxf:ad'] ? response['_embedded']?.['infoxf:ad'] : []
                this.verticalBanners = this.filterAdsVertical(ads)
                this.billboardBanners = this.filterAdsBillboard(ads)
                this.redirectTranslation = this.post['_links']['infoxf:translation'].filter(i => i.lang !== this.$i18n.locale())[0].href
            }).catch(error => {
                this.axiosCatch(error)
            }).finally(() => {
                this.skeleton = false
            })
        }
    },
}
</script>

<style scoped>

</style>
