<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="skeleton" type="text@1" width="50%"></v-skeleton-loader>
                <contents-breadcrumbs-component v-else :items="breadcrumbs"></contents-breadcrumbs-component>
            </v-col>
        </v-row>
        <v-row v-if="skeleton">
            <v-col cols="12" lg="10">
                <v-skeleton-loader v-if="skeleton" type="heading"></v-skeleton-loader>
            </v-col>
            <v-divider class="hidden-md-and-down" vertical></v-divider>
            <v-col cols="12" lg="2">
                <v-skeleton-loader v-if="skeleton" type="text@2"></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-if="skeleton">
            <v-col>
                <v-skeleton-loader type="image"></v-skeleton-loader>
                <v-skeleton-loader class="my-4" :key="i" v-for="i in 2" type="text@3"></v-skeleton-loader>
                <v-skeleton-loader type="image" width="50%"></v-skeleton-loader>
                <v-skeleton-loader class="my-4" :key="i" v-for="i in 2" type="text@5"></v-skeleton-loader>
                <v-skeleton-loader type="image" width="50%"></v-skeleton-loader>
            </v-col>
        </v-row>

        <v-row v-if="!skeleton && post">
            <v-col cols="12" lg="9">
                <post-title :post="post"/>
                <p
                    class="hidden-lg-and-up read_in_minutes"
                    v-html="$t('contents.read_in_minutes', {minutes:readInMinutes}, readInMinutes)"
                ></p>
                <p v-if="isPresentPost" class="wp-date">{{ post.date | date }}</p>
            </v-col>
            <v-spacer class="hidden-md-and-down"/>
            <v-divider class="hidden-md-and-down" vertical/>
            <p
                class="pl-3 text-right read_in_minutes hidden-md-and-down align-self-start"
                v-html="$t('contents.read_in_minutes', {minutes:readInMinutes}, readInMinutes)"
            ></p>
        </v-row>
        <v-row v-if="!skeleton && post">
            <v-col>
                <v-img
                    v-if="post._embedded['wp:featuredmedia']"
                    :class="{'mr-3': isShort, 'float-md-left': isShort, 'mb-8': !isShort}"
                    :width="isShort ? '50%' : '100%'"
                    :src="post._embedded['wp:featuredmedia'][0].source_url"
                ></v-img>
                <contents-share-component :url="shareUrl"></contents-share-component>
                <div class="wordpress-post" v-html="post.content.rendered"></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import blog from "../../mixins/blog";

const READ_WORDS_PER_MINUTE = 200
import PostTitle from '@/components/contents/PostTitle'

export default {
    components: {
        PostTitle,
    },
    props: {
        post: {
            type: Object,
            default () {
                return {}
            }
        },
        skeleton: {
            type: Boolean,
            default: false,
        }
    },
    mixins: [blog],
    computed: {
        breadcrumbs () {
            let bc = []

            if (this.post) {
                this.post._embedded['wp:term'][0].forEach(i => {
                    bc.push({
                        text: i.name,
                        to: { name: 'contents.category', params: { category: i.id, title: this.$options.filters.slug(i.name) } }
                    })
                })

                bc.push({
                    text: this.post.title.rendered
                })
            }

            return bc
        },
        readInMinutes () {
            return Math.max(1, Math.floor(this.post.content.rendered.split(' ').length / READ_WORDS_PER_MINUTE))
        },
        isShort () {
            return this.post && this.post.format === 'short' && this.$vuetify.breakpoint.mdAndUp
        },
        isPresentPost (){
            return this.post &&
            this.post._embedded &&
            this.post._embedded['wp:term'] &&
            this.post._embedded['wp:term'][0] &&
            this.post._embedded['wp:term'][0].some(i => i.id === this.PRESENT_CATEGORY)
        },
        shareUrl(){
            return `${this.post.link}/${this.$options.filters.slug(this.post.title.rendered)}`
        }
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/wordpress.scss";
@import "@/assets/scss/wordpress/_wp-variables.scss";


.v-application .read_in_minutes {
    float: right;
    font-size: 90%;

    .minutes,
    .units {
        color: $wp-vivid-red;
    }
}

@media screen and (min-width: 1264px) {
    .read_in_minutes {
        float: none;
        align-self: center;
        display: grid;
        grid-column-gap: 0.325rem;
        grid-template-columns: max-content max-content;
        grid-template-rows: max-content max-content;
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        .text {
            font-size: 13px;
            grid-column: 1;
            grid-row: 1;
            align-self: end;
        }

        .units {
            font-size: 23px;
            grid-column: 1;
            grid-row: 2;
            line-height: 1em;
        }

        .minutes {
            font-size: 52px;
            grid-column: 2;
            grid-row: 1 / 3;
        }
    }

}


</style>
