<template>
    <contents-layout :preview="true">
        <contents-post-component :post="post"></contents-post-component>
    </contents-layout>
</template>

<script>
import blog from '@/mixins/blog'
import utils from "@/mixins/utils";

export default {
    metaInfo(){
        return {
            title: this.post ? this.decodeHtml(this.post.title.rendered) : null
        }
    },
    data() {
        return {
            post: null,
            skeleton: true,
        }
    },
    mixins: [blog, utils],
    mounted() {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi() {
            this.getPreviewPost(this.$route.params.post, this.$route.params.token).then(response => {
                this.post = response
            }).catch(error => {
                this.axiosCatch(error)
            }).finally(() => {
                this.skeleton = false
            })
        }
    },
}
</script>

<style scoped>

</style>
