import * as types from '@/store/mutation-types';

export default {
    namespaced: true,
    state: {
        counter: 0,
        items: [],
    },

    getters: {
        counter: state => state.counter,
        items: state => state.items,
    },

    mutations: {
        [types.PUSH_ALERT](state, data) {
            let id = state.counter++;

            state.items.push({
                id: id,
                msg: data.msg,
                type: data.type,
                details: data.details
            });

            if (data.type !== 'error') {
                setTimeout(() => {
                    state.items = state.items.filter(item => item.id !== id)
                }, 7000)
            }
        },
        [types.REMOVE_ALERT](state, data) {
            state.items = state.items.filter(item => item.id !== data.id)
        },
        [types.RESET_ALERT](state) {
            state.items = [];
            state.counter = 0;
        },
    },

    actions: {
        push({commit}, data) {
            commit(types.PUSH_ALERT, data);
        },
        remove({commit}, data) {
            commit(types.REMOVE_ALERT, data);
        },
        reset({commit}) {
            commit(types.RESET_ALERT);
        }
    }
}
