<template>
    <div class="mb-6">
        <v-btn
            v-for="(item, i) in items"
            :key="i"
            color="red"
            class="me-4"
            dark
            fab
            small
            :href="item.href"
            target="_blank"
        >
            <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default() {
                return null
            }
        }
    },
    data() {
        return {
            items: [
                {
                    icon: 'mdi-facebook',
                    href: `https://www.facebook.com/sharer/sharer.php?u=${this.url}`
                },
                {
                    icon: 'mdi-twitter',
                    href: `https://twitter.com/intent/tweet?text=${this.url}`
                },
                {
                    icon: 'mdi-whatsapp',
                    href: `https://api.whatsapp.com/send?text=${this.url}`
                },
                {
                    icon: 'mdi-linkedin',
                    href: `https://www.linkedin.com/shareArticle?mini=true&url=${this.url}`
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>
