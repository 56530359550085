<template>
    <div v-if="items.length > 0" class="alert">
        <v-snackbar
            v-for="(item, index) in items"
            :timeout="item.type === 'error' ? -1 : 7000"
            :key="item.id"
            :color="item.type"
            v-model="item.msg"
            multi-line
            top
            left
            :style="{'margin-top':calcMargin(index)}"
        >
            <span>{{ item.msg }}</span>
            <v-divider
                v-if="item.details"
                class="my-4"
            ></v-divider>
            <span v-if="item.details">{{ item.details.substring(0, 250) }}</span>
            <span v-if="item.details && item.details.length > 250">...</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    icon
                    @click="closeAlert(item.id)"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    methods: {
        closeAlert(id) {
            this.$store.dispatch('alert/remove', {
                id: id
            });
        },
        calcMargin(i) {
            return (i * 70) + 'px';
        },
    },
    computed: mapGetters({
        items: 'alert/items'
    }),
}
</script>
