<template>
    <div :style="background" class="cover">
        <v-container fluid class="pa-0 fill-height">
            <v-row no-gutters class="fill-height">
                <v-col
                    cols="6"
                    :style="customerBackground"
                    class="d-flex align-center justify-center customer"
                >
                    <v-btn class="pt-12 ps-12" text disabled absolute top left>
                        <v-img src="@/assets/img/logos/xf-wrapped.png"
                               max-width="75"
                               max-height="75">
                        </v-img>
                    </v-btn>
                    <language-selector-component
                        :absolute="true"
                        :top="true"
                        :right="true"
                        color="white"
                    ></language-selector-component>
                    <v-btn
                        :class="customerBtnClass"
                        x-large
                        dark
                        outlined
                        @mouseover="customerHover = true"
                        @mouseleave="customerHover = false"
                        @click="redirectCustomer()"
                    >
                        {{ $t('intro.customer') }}
                    </v-btn>
                </v-col>
                <v-col
                    cols="6"
                    :style="pharmacyBackground"
                    class="d-flex align-center justify-center fill-height pharmacy"
                >
                    <v-btn
                        :class="pharmacyBtnClass"
                        x-large
                        dark
                        outlined
                        @mouseover="pharmacyHover = true"
                        @mouseleave="pharmacyHover = false"
                        @click="redirectPharmacy()"
                    >
                        {{ $t('intro.pharmacy') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <footer-component></footer-component>
    </div>
</template>

<script>
export default {
    data() {
        return {
            customerHover: false,
            pharmacyHover: false,
            backgrounds: [
                require('@/assets/img/intro/bg-1-fullhd.jpg'),
                require('@/assets/img/intro/bg-2-fullhd.jpg'),
                require('@/assets/img/intro/bg-3-fullhd.jpg'),
            ],
            randomBackground: null,
        };
    },
    methods: {
        redirectCustomer() {
            this.$router.push({ name: 'home' });
        },
        redirectPharmacy() {
            this.$router.push({ name: 'pharmacy.home' });
        }
    },
    computed: {
        backgroundImage() {
            return this.backgrounds[this.randomBackground];
        },
        background() {
            return {
                'background-image': `url(${ this.backgroundImage })`,
                'background-repeat': 'no-repeat',
                'background-size': 'cover',
                'background-attachment': 'fixed',
                'background-position': 'center top',
                'height': '100vh',
            }
        },
        customerBackground() {
            if (!this.customerHover) {
                return {
                    'background-color': 'rgba(174, 17, 10, 0.5)'
                };
            } else {
                return {
                    'background-color': 'transparent',
                }
            }
        },
        pharmacyBackground() {
            if (!this.pharmacyHover) {
                return {
                    'background-color': 'rgba(230, 31, 43, 0.5)'
                };
            } else {
                return {
                    'background-color': 'transparent',
                }
            }
        },
        pharmacyBtnClass() {
            if (this.pharmacyHover) {
                return 'py-sm-10 px-sm-6 px-1 py-2 text-sm-h5 text-body-2 btn-outlined rounded-lg xf';
            } else {
                return 'py-sm-10 px-sm-6 px-1 py-2 text-sm-h5 text-body-2 btn-outlined rounded-lg';
            }
        },
        customerBtnClass() {
            if (this.customerHover) {
                return 'py-sm-10 px-sm-6 px-1 py-2 text-sm-h5 text-body-2 btn-outlined rounded-lg xf';
            } else {
                return 'py-sm-10 px-sm-6 px-1 py-2 text-sm-h5 text-body-2 btn-outlined rounded-lg';
            }
        }
    },
    mounted() {
        this.randomBackground = Math.floor(Math.random() * this.backgrounds.length);
        setInterval(() => {
            this.randomBackground = (this.randomBackground + 1) % this.backgrounds.length;
        }, 8000);
    }
}
</script>

<style scoped>
@media (min-width: 600px) {
    .btn-outlined {
        border-width: medium;
    }
}

@media (max-width: 600px) {
    .btn-outlined {
        border-width: thin;
    }
}

footer {
    box-shadow: 0 -10px 50px #333333 !important;
}
</style>
