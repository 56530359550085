<template>
    <v-menu offset-y>
        <template #activator="{ on }">
            <v-btn
                text
                v-on="on"
                :color="color"
                :absolute="absolute"
                :top="top"
                :right="right"
            >
                {{ $t('menu.' + $i18n.locale()) }}
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                v-for="item in locales"
                :key="item"
                @click="setLocale(item)"
            >
                <v-list-item-title>{{ $t('menu.' + item) }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {
        absolute: {
            type: Boolean,
            default: false
        },
        top: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary'
        },
    },
    data() {
        return {
            locales: ['ca', 'es'],
        }
    },
    methods: {
        setLocale(locale) {
            this.$i18n.set(locale);
            localStorage.setItem('locale', locale)
        }
    },
};
</script>
