<template>
    <v-footer class="xf-background-gradient px-md-12">
        <v-container fluid class="py-4 px-md-12">
            <v-row align="center">
                <v-col cols="12" md="3" class="text-center text-md-left">
                    <v-avatar tile>
                        <v-img
                            src="@/assets/img/logos/xf-white.png"
                            class="ma-auto mx-md-0"
                            max-width="50"
                            max-height="50"
                        ></v-img>
                    </v-avatar>
                </v-col>
                <v-col cols="12" md="6">
                    <p class="text-no-wrap text-center text-h6 white--text ma-0">
                        xarxafarma.com | neoxfarma.com
                    </p>
                </v-col>
                <v-col cols="12" md="3" class="text-center text-md-right">
                    <v-btn
                        class="px-5"
                        icon
                        href="https://www.facebook.com/XarxaFarmaOficial"
                        target="_blank"
                    >
                        <v-img
                            src="@/assets/img/social/facebook.png"
                            max-width="25"
                            max-height="25"
                        ></v-img>
                    </v-btn>
                    <v-btn
                        class="px-5"
                        icon
                        href="https://twitter.com/xarxafarma"
                        target="_blank"
                    >
                        <v-img
                            src="@/assets/img/social/twitter.png"
                            max-width="25"
                            max-height="25"
                        ></v-img>
                    </v-btn>
                    <v-btn
                        class="px-5"
                        icon
                        href="https://www.instagram.com/xarxafarma"
                        target="_blank"
                    >
                        <v-img
                            src="@/assets/img/social/instagram.png"
                            max-width="25"
                            max-height="25"
                        ></v-img>
                    </v-btn>
                    <v-btn
                        class="px-5"
                        icon
                        href="https://www.linkedin.com/company/xarxafarma"
                        target="_blank"
                    >
                        <v-img
                            src="@/assets/img/social/linkedin.png"
                            max-width="25"
                            max-height="25"
                        ></v-img>
                    </v-btn>
                    <v-btn
                        class="px-5"
                        icon
                        href="https://www.youtube.com/channel/UCAoPJMCuczQf3rTlUHZYlzQ"
                        target="_blank"
                    >
                        <v-img
                            src="@/assets/img/social/youtube.png"
                            max-width="25"
                            max-height="25"
                        ></v-img>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col class="text-center">
                    <v-btn v-if="pharmacy" :to="{name: 'pharmacy.announcement'}" text dark>
                        {{ $t('menu.announcement') }}
                    </v-btn>
                    <v-btn v-if="pharmacy" :to="{name: 'pharmacy.cookies'}" text dark>
                        {{ $t('menu.cookies') }}
                    </v-btn>
                    <v-btn v-else :to="{name: 'cookies'}" text dark>
                        {{ $t('menu.cookies') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
export default {
    props: {
        pharmacy: {
            type: Boolean,
            default: false,
        },
    }
}
</script>

<style scoped>

</style>
