<template>
    <v-row :class="'fill-height ' + colorFill" align="center">
        <v-col md="6" cols="12" :class="classColRight">
            <v-row>
                <v-col class="px-12 pe-md-3 text-justify text-md-right">
                    <h1><strong>{{ title }}</strong></h1>
                </v-col>
            </v-row>
            <header-divider-component></header-divider-component>
            <v-row>
                <v-col class="px-12 pe-md-3 text-justify text-md-right">
                    <slot name="content"></slot>
                </v-col>
            </v-row>
        </v-col>
        <v-col md="6" cols="12" :class="classColLeft">
            <slot name="img"></slot>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            Default: null,
        },
        colorFill: {
            type: String,
            default: null,
        },
        classColRight: {
            type: String,
            Default: null,
        },
        classColLeft: {
            type: String,
            Default: null,
        }
    }
}
</script>

<style scoped>
.text-right img {
    display: inline-block;
}
</style>
