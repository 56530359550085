import * as types from '../mutation-types';

export default {
    namespaced: true,
    state: {
        loading: false,
    },

    getters: {
        loading: state => state.loading,
    },

    mutations: {
        [types.LOADING](state, data) {
            state.loading = data;
        },
    },

    actions: {
        loading({commit}, data) {
            commit(types.LOADING, data);
        },
    }
}
