<template>
    <v-card
        :to="{name: 'contents.post', params:{post: post.id, title: $options.filters.slug(post.title.rendered)}}"
        tile
        flat
        class="wp-featured-post"
        v-if="hasFeaturedImage"
    >
        <v-img
            :src="featuredImage.source_url"
            :aspect-ratio="2"
            gradient="to bottom, rgba(100,100,100,.33), rgba(0,0,0,.7)"
            class="white--text align-end"
            max-height="512px"
        >
            <v-card-subtitle
                v-html="categoryName"
                class="wp-category-title ml-4"
            />
            <v-card-title
                v-html="post.title.rendered"
                class="text-h4 mb-16 ml-4"
                style="word-break: break-word; max-width: 75%"
            />
        </v-img>
    </v-card>
</template>

<script>
export default {
    name: 'FeaturedPost',
    props: {
        post: Object,
    },
    computed: {
        hasFeaturedImage () {
            const embedded = this.post && this.post._embedded['wp:featuredmedia']
            return embedded && embedded.length > 0
        },
        featuredImage () {
            return this.hasFeaturedImage ? this.post._embedded['wp:featuredmedia'][0] : null
        },
        categoryName () {
            return this.post._embedded['wp:term'][0] ? this.post._embedded['wp:term'][0][0].name : ''
        },
    },
}
</script>

<style scoped>

</style>
