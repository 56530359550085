<template>
    <main-layout>
        <v-container class="404 text-center">
            <v-row>
                <v-col class="mx-auto">
                    <h1 class="display-4">Error 404</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p>{{ $t('errors.404') }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-icon
                        color="error"
                        size="100"
                    >
                        mdi-help-circle-outline
                    </v-icon>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p>{{ $t('errors.404_check') }} <br/> <strong>{{ path }}</strong></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p>{{ $t('errors.contact') }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <router-link
                        :to="{name: 'home'}"
                    >
                        <v-btn dark>
                            {{ $t('actions.go_back_home') }}
                        </v-btn>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </main-layout>
</template>

<script>
export default {
    data() {
        return {
            path: this.$router.currentRoute.path,
        }
    }
}
</script>

<style scoped>

</style>
