<template>
    <v-container fluid class="px-0 py-12 red lighten-5">
        <v-row>
            <v-col class="text-center">
                <h1>{{ $t('home.pharmacy_services_title') }}</h1>
            </v-col>
        </v-row>
        <header-divider-component></header-divider-component>
        <v-row class="px-12" justify="center">
            <v-col
                :key="index"
                v-for="(item, index) in all_services"
                md="6"
                lg="3"
            >
                <v-card
                    flat
                    @click="item.reveal = !item.reveal"
                    color="transparent"
                    max-height="250px"
                >
                    <v-img
                        :src="item.img"
                        :alt="item.header"
                        contain
                        height="250px"
                    ></v-img>
                    <v-fade-transition>
                        <v-card
                            v-if="item.reveal"
                            class="v-card--reveal"
                        >
                            <v-card-text class="text-center">
                                <p class="text-h5">{{ item.header }}</p>
                                <p class="body-1">{{ item.content }}</p>
                            </v-card-text>
                        </v-card>
                    </v-fade-transition>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            all_services: [],
        }
    },
    methods: {
        loadImages() {
            this.all_services = [
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-5.png`),
                    header: this.$i18n.translate('home.pharmacy_service_1_title'),
                    content: this.$i18n.translate('home.pharmacy_service_1'),
                    reveal: false,
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-6.png`),
                    header: this.$i18n.translate('home.pharmacy_service_2_title'),
                    content: this.$i18n.translate('home.pharmacy_service_2'),
                    reveal: false,
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-7.png`),
                    header: this.$i18n.translate('home.pharmacy_service_3_title'),
                    content: this.$i18n.translate('home.pharmacy_service_3'),
                    reveal: false,
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-8.png`),
                    header: this.$i18n.translate('home.pharmacy_service_4_title'),
                    content: this.$i18n.translate('home.pharmacy_service_4'),
                    reveal: false,
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-9.png`),
                    header: this.$i18n.translate('home.pharmacy_service_5_title'),
                    content: this.$i18n.translate('home.pharmacy_service_5'),
                    reveal: false,
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-10.png`),
                    header: this.$i18n.translate('home.pharmacy_service_6_title'),
                    content: this.$i18n.translate('home.pharmacy_service_6'),
                    reveal: false,
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-11.png`),
                    header: this.$i18n.translate('home.pharmacy_service_7_title'),
                    content: this.$i18n.translate('home.pharmacy_service_7'),
                    reveal: false,
                },
                {
                    img: require(`@/assets/img/home-pharmacy/${this.locale}/icon-12.png`),
                    header: this.$i18n.translate('home.pharmacy_service_8_title'),
                    content: this.$i18n.translate('home.pharmacy_service_8'),
                    reveal: false,
                }
            ]
        },
        services() {
            this.$router.push({name: 'pharmacy.services'});
        }
    },
    watch: {
        locale() {
            this.loadImages();
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
    },
    mounted() {
        this.loadImages();
    }
}
</script>

<style scoped>
.v-card--reveal {
    top: 0;
    position: absolute;
    height: 100%;
}
</style>
