import Vue from 'vue';
import HeaderComponent from '@/components/HeaderComponent';
import FooterComponent from '@/components/FooterComponent';
import AlertComponent from '@/components/AlertComponent';
import LanguageSelectorComponent from '@/components/LanguageSelectorComponent';
import LoaderPageComponent from '@/components/LoaderPageComponent';
import HeaderDividerComponent from '@/components/HeaderDividerComponent';
import PharmacyCardComponent from '@/components/PharmacyCardComponent';
import MapComponent from '@/components/MapComponent';
import GoTopComponent from '@/components/GoTopComponent';
import CookiesComponent from '@/components/CookiesComponent';
import CookiesContentComponent from '@/components/CookiesContentComponent';
import ContentsMenuComponent from "@/components/contents/ContentsMenuComponent";
import ContentsLateralComponent from "@/components/contents/ContentsLateralComponent";
import ContentsBreadcrumbsComponent from "@/components/contents/ContentsBreadcrumbsComponent";
import ContentsSearchComponent from "@/components/contents/ContentsSearchComponent";
import ContentsCategoryPostsComponent from "@/components/contents/ContentsCategoryPostsComponent";
import ContentsPostComponent from "@/components/contents/ContentsPostComponent";
import ContentsThumbnailPostsComponent from "@/components/contents/ContentsThumbnailPostsComponent";
import ContentsPresentPostsComponent from "@/components/contents/ContentsPresentPostsComponent";
import ContentsAdComponent from "@/components/contents/ContentsAdComponent";
import EventFieldsComponent from '@/components/EventFieldsComponent';
import ContentsShareComponent from '@/components/contents/ContentsShareComponent';

Vue.component('header-component', HeaderComponent);
Vue.component('footer-component', FooterComponent);
Vue.component('alert-component', AlertComponent);
Vue.component('language-selector-component', LanguageSelectorComponent);
Vue.component('loader-page-component', LoaderPageComponent);
Vue.component('header-divider-component', HeaderDividerComponent);
Vue.component('pharmacy-card-component', PharmacyCardComponent);
Vue.component('map-component', MapComponent);
Vue.component('go-top-component', GoTopComponent);
Vue.component('cookies-component', CookiesComponent);
Vue.component('cookies-content-component', CookiesContentComponent);
Vue.component('contents-menu-component', ContentsMenuComponent);
Vue.component('contents-lateral-component', ContentsLateralComponent);
Vue.component('contents-breadcrumbs-component', ContentsBreadcrumbsComponent);
Vue.component('contents-search-component', ContentsSearchComponent);
Vue.component('contents-category-posts-component', ContentsCategoryPostsComponent);
Vue.component('contents-post-component', ContentsPostComponent);
Vue.component('contents-thumbnail-posts-component', ContentsThumbnailPostsComponent);
Vue.component('contents-present-posts-component', ContentsPresentPostsComponent);
Vue.component('contents-ad-component', ContentsAdComponent);
Vue.component('event-fields-component', EventFieldsComponent);
Vue.component('contents-share-component', ContentsShareComponent);
