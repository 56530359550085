<template>
    <main-layout
        :header="header"
        :footer="footer"
        :pharmacy="pharmacy"
        :absolute="absolute"
        :gotop="gotop"
        :links="links"
    >
        <slot></slot>
    </main-layout>
</template>

<script>
export default {
    props: {
        header: {
            type: Boolean,
            default: false,
        },
        footer: {
            type: Boolean,
            default: true,
        },
        pharmacy: {
            type: Boolean,
            default: false,
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        gotop: {
            type: Boolean,
            default: false,
        },
        links: {
            type: Array,
            default() {
                return [];
            }
        }
    },
}
</script>

<style scoped>

</style>
