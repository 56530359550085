<template>
    <v-breadcrumbs :items="items" class="pa-0">
        <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template #item="{item}">
            <li>
                <router-link
                    v-if="item.to"
                    :to="item.to"
                    class="v-breadcrumbs__item"
                    v-html="item.text"
                ></router-link>
                <div v-else class="v-breadcrumbs__item" v-html="item.text"></div>
            </li>
        </template>
    </v-breadcrumbs>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default () {
                return []
            }
        }
    }
}
</script>

<style scoped>

</style>
