import Welcome from '@/pages/welcome';
import PageNotFound from '@/pages/errors/404';

export default [
    {
        path: '/',
        name: 'welcome',
        component: Welcome,
    },
    {
        path: '*',
        name: '404',
        component: PageNotFound
    },
];
