import Home from "@/pages/customer/home";
import Card from "@/pages/customer/card";
import ContentsHome from "@/pages/customer/contents/home";
import ContentsMagazines from "@/pages/customer/contents/magazines";
import ContentsDiptychs from "@/pages/customer/contents/diptychs";
import ContentsCategory from "@/pages/customer/contents/category";
import ContentsSearch from "@/pages/customer/contents/search";
import ContentsPost from "@/pages/customer/contents/post";
import ContentsPreview from "@/pages/customer/contents/preview";
import Contents from "@/pages/customer/contents/index";
import Map from "@/pages/customer/map";
import Contact from "@/pages/customer/contact";
import Cookies from "@/pages/customer/cookies";

export default [
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/card',
        name: 'card',
        component: Card,
    },
    {
        path: '/map',
        name: 'map',
        component: Map,
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
    },
    {
        path: '/cookies',
        name: 'cookies',
        component: Cookies,
    },
    {
        path: '/contents',
        redirect: { name: 'contents', params: { locale: 'ca' } }
    },
    {
        path: '/contents/:locale',
        component: Contents,
        children: [
            {
                path: '',
                name: 'contents',
                component: ContentsHome,
            },
            {
                path: 'magazines/:magazine?',
                name: 'contents.magazines',
                component: ContentsMagazines,
            },
            {
                path: 'diptychs',
                name: 'contents.diptychs',
                component: ContentsDiptychs,
            },
            {
                path: 'category/:category/:title?',
                name: 'contents.category',
                component: ContentsCategory,
            },
            {
                path: 'search/:text',
                name: 'contents.search',
                component: ContentsSearch,
            },
            {
                path: 'post/:post/:title?',
                name: 'contents.post',
                component: ContentsPost,
            },
            {
                path: 'preview/:post/:token/:title?',
                name: 'contents.preview',
                component: ContentsPreview,
            },
        ]
    },
];
