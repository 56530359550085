<template>
    <right-block :title="$t('home.card')">
        <template v-slot:img>
            <v-img
                :src="card"
                contain
            ></v-img>
        </template>
        <template v-slot:content>
            <v-row>
                <v-col>
                    <p v-html="$t('home.card_content')"></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        class="xf"
                        x-large
                        dark
                        @click="info"
                    >
                        {{ $t('actions.more_info') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </right-block>
</template>

<script>
export default {
    methods: {
        info() {
            this.$router.push({ name: 'card' });
        }
    },
    computed: {
        card() {
            return require('@/assets/img/home/card.jpg');
        }
    },
}
</script>

<style scoped>

</style>
