<template>
    <contents-layout
        :verticalBanners="verticalBanners"
        :billboardBanners="billboardBanners"
    >
        <v-container fluid class="pa-0 ma-0">
            <v-row>
                <v-col>
                    <h1>{{ $t('menu.content_search', {text: $route.params.text}) }}</h1>
                </v-col>
            </v-row>
            <v-row v-if="!skeleton && posts.length === 0">
                <v-col>
                    <v-alert type="warning">{{ $t('errors.contents_empty_posts') }}</v-alert>
                </v-col>
            </v-row>
            <v-row v-else>
                <template
                    v-for="post in posts"
                >
                    <v-col
                        cols="12"
                        md="6"
                        xl="4"
                        :key="post.id"
                    >
                        <thumbnail-post
                            :skeleton="skeleton"
                            :post="post"
                            class="mb-6"
                        />
                    </v-col>
                </template>
            </v-row>
            <v-pagination
                v-model="query.page"
                :length="query.totalPages"
            ></v-pagination>
        </v-container>
    </contents-layout>
</template>

<script>
import blog from '@/mixins/blog'
import thumbnailPost from "../../../components/contents/ThumbnailPost";

export default {
    components: {
        thumbnailPost,
    },
    metaInfo() {
        return {
            title: this.$i18n.translate('menu.content_search', {text: this.$route.params.text})
        }
    },
    data() {
        return {
            query: {
                perPage: 9,
                page: 1,
                totalPages: 1
            },
            skeleton: true,
            posts: new Array(5).fill({}),
            verticalBanners: [],
            billboardBanners: [],
        }
    },
    watch: {
        query: {
            handler() {
                this.getPosts(this.query.page, this.query.perPage, null, this.$route.params.text).then(response => {
                    this.posts = []
                    this.posts = response.data
                })
            },
            deep: true
        }
    },
    mixins: [blog],
    mounted() {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi() {
            let promises = [
                this.getPosts(this.query.page, this.query.perPage, null, this.$route.params.text),
                this.getAdsHome()
            ]

            axios.all(promises).then((responses) => {
                this.posts = responses[0].data
                this.query.totalPages = parseInt(responses[0].headers['x-wp-totalpages'])
                this.billboardBanners = this.filterAdsBillboard(responses[1])
                this.verticalBanners = this.filterAdsVertical(responses[1])
            }).catch(error => {
                this.posts = []
                this.axiosCatch(error)
            }).finally(() => {
                this.skeleton = false
            })
        }
    },
}
</script>
