<template>
    <v-container>
        <v-row>
            <v-col class="text-center">
                <v-progress-circular
                    :size="250"
                    :width="10"
                    color="orange lighten-3"
                    indeterminate
                ></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {}
</script>
