<template>
    <pharmacy-layout>
        <v-container fluid class="pharmacy-home pa-0">
            <services-external-block color="grey"></services-external-block>
            <banner-block :banners="banners"></banner-block>
            <home-pharmacy-strength-block></home-pharmacy-strength-block>
            <home-pharmacy-quality-block></home-pharmacy-quality-block>
            <home-pharmacy-value-block></home-pharmacy-value-block>
            <home-pharmacy-map-block></home-pharmacy-map-block>
            <v-row class="py-4">
                <v-col>&nbsp;</v-col>
            </v-row>
            <home-pharmacy-services-block class="mb-12"></home-pharmacy-services-block>
            <!--<home-pharmacy-dates-block></home-pharmacy-dates-block>-->
            <home-pharmacy-contact-block class="grey lighten-3"></home-pharmacy-contact-block>
        </v-container>
    </pharmacy-layout>
</template>

<script>
import blog from '@/mixins/blog'

export default {
    metaInfo () {
        return {
            title: `${this.$i18n.translate('menu.home')} ${this.$i18n.translate('menu.pharmacy').toLowerCase()}`
        }
    },
    data () {
        return {
            ads: [],
            sliders: {
                ca: [],
                es: [],
            }
        }
    },
    mixins: [blog],
    methods: {
        getDataFromApi () {
            this.getAdsHome().then((response) => {
                this.ads = this.filterAdsNetboard(response)
            }).catch(error => {
                this.axiosCatch(error)
            })
        }
    },
    watch: {
        blogLocale () {
            this.getDataFromApi()
        },
    },
    computed: {
        locale () {
            return this.$i18n.locale()
        },
        banners () {
            let images = []
            this.sliders[this.locale].map(i => {
                let image = { src: require(`@/assets/img/sliders/pharmacy/${this.locale}/${i}`) }
                try {
                    const extra = require(`@/assets/img/sliders/pharmacy/${this.locale}/${i}.json`)
                    image = { ...image, ...extra }
                } catch (ex) {
                    // eslint-disable-next-line
                    console.log(ex)
                }
                images.push(image)
            })
            this.ads.map(({ img, href }) => {
                images.push({
                    src: img,
                    href,
                })
            })
            return images
        },
    },
    created () {
        this.getDataFromApi()
        require.context('@/assets/img/sliders/pharmacy/', true, /(.*)(gif|jpg|jpeg|tiff|png)$/).keys().forEach(i => {
            if (i.endsWith('.json')) return
            let segments = i.replace('./', '').split('/')
            this.sliders[segments[0]].push(segments[1])
        })
    }
}
</script>

<style scoped>

</style>
