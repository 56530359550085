<template>
    <contents-layout
        :verticalBanners="verticalBanners"
        :show-most-read-posts="false"
    >
        <template v-slot:billboard>
            <v-skeleton-loader
                v-if="skeleton"
                type="image"
            />
            <featured-post
                class="mt-6 mb-6"
                v-else-if="featuredPost"
                :post="featuredPost"
            />
        </template>

        <contents-present-posts-component
            class="float-md-right"
            :skeleton="skeleton"
            :width="cardWidth"
            :posts="presentPosts"
        />

        <template
            v-for="(post, i) in topPosts"
        >
            <thumbnail-short-post
                :key="i"
                v-if="post.format === 'short'"
                :post="post"
                :width="cardWidth"
                :skeleton="skeleton"
                class="d-md-inline-block pa-2 mb-6"
            />
            <thumbnail-post
                :key="i"
                v-else
                :post="post"
                :width="cardWidth"
                :skeleton="skeleton"
                class="d-md-inline-block pa-2 mb-6"
            />
        </template>

        <template v-slot:after-content>
            <v-row>
                <v-col>
                    <thumbnail-post
                        v-for="(post, i) in middlePosts"
                        :key="i"
                        :post="post"
                        :width="cardWidth"
                        :show-excerpt="!$vuetify.breakpoint.mdAndUp"
                        class="d-md-inline-block pa-2 mb-6"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <contents-ad-component type="billboard" :items="billboardBanners"></contents-ad-component>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <h4 class="text-h5 text-uppercase">{{ $t('menu.most_read') }}</h4>
                </v-col>
            </v-row>
            <v-row>
                <template
                    v-for="(post, i) in mostReadPosts"
                >
                    <v-divider
                        :key="'divider-' + i"
                        v-if="i > 0"
                        vertical
                    />
                    <v-col
                        :key="'col-' + i"
                        cols="12"
                        md="3"
                    >
                        <v-skeleton-loader
                            v-if="skeleton"
                            type="card"
                        />
                        <thumbnail-post
                            v-else
                            :post="post"
                            :show-excerpt="false"
                        />
                    </v-col>
                </template>
            </v-row>

            <v-row
                v-if="showFeaturedPost"
                class="mt-16 mb-16"
            >
                <v-col>
                    <fullwidth-excerpt
                        :post="fullwidthPost"
                    />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="3">
                    <contents-ad-component
                        type="vertical"
                        :items="verticalBanners"
                    />
                </v-col>
                <v-col cols="12" md="9">
                    <thumbnail-post
                        v-if="!showFeaturedPost"
                        :post="fullwidthPost"
                        :show-excerpt="false"
                    />
                    <thumbnail-post
                        v-for="(post, i) in bottomPosts"
                        :key="i"
                        :post="post"
                        :width="cardWidth"
                        :show-excerpt="false"
                        class="d-md-inline-block pa-2 mb-6"
                    />
                </v-col>
            </v-row>
        </template>
    </contents-layout>
</template>

<script>

import blog from '@/mixins/blog'
import FeaturedPost from '@/components/contents/FeaturedPost'
import ThumbnailPost from '@/components/contents/ThumbnailPost'
import FullwidthExcerpt from '@/components/contents/FullwidthExcerpt'
import ThumbnailShortPost from '@/components/contents/ThumbnailShortPost'

export default {
    data () {
        return {
            latestPosts: Array(4).fill({}),
            allPresentPosts: Array(6).fill({}),
            mostReadPosts: Array(4).fill({}),
            verticalBanners: [],
            billboardBanners: [],
            skeleton: true,
        }
    },
    components: {
        ThumbnailShortPost,
        FeaturedPost,
        ThumbnailPost,
        FullwidthExcerpt,
    },
    mixins: [blog],
    methods: {
        getDataFromApi () {
            let promises = [
                this.getLatestPosts(13),
                this.getPresentPosts(),
                this.getAdsHome(),
                this.getMostReadPosts(),
            ]

            axios.all(promises).then((responses) => {
                this.latestPosts = responses[0].data
                this.allPresentPosts = responses[1].data
                this.billboardBanners = this.filterAdsBillboard(responses[2])
                this.verticalBanners = this.filterAdsVertical(responses[2])
                this.mostReadPosts = responses[3].data
            }).catch(error => {
                this.axiosCatch(error)
            }).finally(() => {
                this.skeleton = false
            })
        },
    },
    computed: {
        showFeaturedPost () {
            return this.$vuetify.breakpoint.mdAndUp
        },
        presentPosts () {
            return this.showFeaturedPost ? this.allPresentPosts.slice(1) : this.allPresentPosts
        },
        featuredPost () {
            return !this.skeleton && this.allPresentPosts.length > 0 && this.showFeaturedPost ? this.allPresentPosts[0] : false
        },
        cardWidth () {
            return this.$vuetify.breakpoint.mdAndUp ? '50%' : '100%'
        },
        topPosts () {
            return this.latestPosts.slice(0, 4)
        },
        middlePosts () {
            return this.latestPosts.slice(4, 6)
        },
        fullwidthPost () {
            return !this.skeleton && this.allPresentPosts.length > 6 ? this.latestPosts[6] : false
        },
        bottomPosts () {
            return this.latestPosts.slice(7)
        }
    },
    mounted () {
        this.getDataFromApi()
    }
}
</script>

<style scoped>

</style>
