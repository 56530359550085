<template>
    <v-form ref="form">
        <v-row>
            <v-col>
                <v-autocomplete
                    v-if="parties.length > 0"
                    :rules="[ruleRequired]"
                    :items="parties"
                    v-model="partyId"
                    :label="$t('fields.pharmacy')"
                    clearable
                    @click:clear="partyId = null"
                    item-value="id"
                ></v-autocomplete>
                <v-text-field
                    v-else
                    v-model="partyName"
                    :rules="[ruleRequired]"
                    :label="$t('fields.pharmacy')"
                ></v-text-field>
                <v-radio-group v-model="shift" :mandatory="true">
                    <v-radio
                        :key="i"
                        v-for="(shift, i) in shifts"
                        :value="shift.id"
                        :disabled="shift.available <= 0"
                    >
                        <template #label>
                            <div>
                                {{ shift.date | dateformat('DD/MM/YYYY HH:mm') }}
                                <span v-if="shift.available <= 0" class="red--text text--darken-3">
                                    {{ $t('fields.full') }}
                                </span>
                            </div>
                        </template>
                    </v-radio>
                </v-radio-group>
                <v-textarea
                    class="pt-6"
                    rows="10"
                    v-model="attendees"
                    :rules="[ruleRequired]"
                    outlined
                    :label="$t('fields.attendees')"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-caption text-justify">
                <p> {{ $t('fields.contact_legal') }} </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mb-4 text-right">
                <v-btn
                    :loading="loading"
                    dark
                    large
                    color="xf"
                    @click="confirmAttendance"
                >
                    {{ $t('fields.confirm_attendance') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

import rules from '../mixins/rules'
import { mapGetters } from 'vuex'

export default {
    props: {
        shifts: {
            type: Array,
            default () {
                return []
            }
        },
        parties: {
            type: Array,
            default () {
                return []
            }
        },
    },
    data () {
        return {
            confirmed: false,
            partyName: null,
            partyId: null,
            attendees: null,
            shift: null,
        }
    },
    mixins: [rules],
    methods: {
        confirmAttendance () {
            if (this.$refs.form.validate() && !this.loading) {
                this.$store.dispatch('loading/loading', true)
                axios.post(`${this.apiUrl}/api/public/events/attend`, {
                    party_id: this.partyId,
                    attendees: this.attendees.split('\n'),
                    shift_id: this.shift
                }).then(response => {
                    // eslint-disable-next-line no-console
                    console.log(response)
                    this.alert(this.$i18n.translate('success.sent'), 'success')
                    this.$refs.form.reset()
                    this.$store.dispatch('loading/loading', false)
                    this.$emit('confirmedAttendance')
                }).catch(error => {
                    this.axiosCatch(error)
                    this.$store.dispatch('loading/loading', false)
                })
            }
        },
    },
    computed:{
        ...mapGetters({
            loading: 'loading/loading'
        }),
    }
}
</script>

<style scoped>

</style>
