<template>
    <div>
        <banner/>
        <v-app-bar
            color="xf"
        >
            <v-slide-group
                style="min-width: 0; height: inherit;"
                class="hidden-sm-and-down mx-auto"
                show-arrows
                dark
                tag="nav"
            >
                <v-slide-item
                    v-for="(link, i) in links"
                    :key="i"
                >
                    <v-btn
                        text
                        exact
                        :to="link.to"
                        height="100%"
                        style="border-radius: 0;"
                        color="white"
                        class="subtitle-2"
                    >
                        <strong>{{ link.name }}</strong>
                    </v-btn>
                </v-slide-item>
            </v-slide-group>
            <div class="flex-grow-1 hidden-md-and-up"></div>
            <v-menu offset-y>
                <template #activator="{ on }">
                    <v-app-bar-nav-icon dark class="hidden-md-and-up" v-on="on"></v-app-bar-nav-icon>
                </template>
                <v-list>
                    <v-list-item v-for="link in links"
                                 :key="link.id"
                    >
                        <v-btn block
                               text
                               color="xf"
                               :to="link.to"
                        >
                            <strong>{{ link.name }}</strong>
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
import blog from '@/mixins/blog'
import Banner from '@/components/contents/Banner'

export default {
    components: { Banner },
    data () {
        return {
            links: [],
        }
    },
    mixins: [blog],
    watch: {
        blogLocale () {
            this.getDataFromApi()
        }
    },
    computed: {
        blogLocale () {
            return this.$i18n.locale()
        },
        linksBefore () {
            return [
                {
                    name: this.$i18n.translate('menu.home'),
                    to: { name: 'contents' }
                },
            ]
        },
        linksAfter () {
            return [
                {
                    name: this.$i18n.translate('menu.magazines_history'),
                    to: { name: 'contents.magazines' }
                },
                {
                    name: this.$i18n.translate('menu.diptychs'),
                    to: { name: 'contents.diptychs' }
                },
            ]
        }
    },
    mounted () {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi () {
            this.$store.dispatch('loading/loading', true)
            this.getCategories().then(response => {
                this.links = []
                this.links.push(...this.linksBefore)
                this.links.push(...response.map(i => {
                    i.to = { name: 'contents.category', params: { category: i.id, title: this.$options.filters.slug(i.name) } }
                    return i
                }))
                this.links.push(...this.linksAfter)
            }).catch(error => {
                this.axiosCatch(error)
            }).finally(() => {
                this.$store.dispatch('loading/loading', false)
            })
        },
    }
}
</script>

<style scoped>

</style>
