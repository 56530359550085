import Actions from '@/assets/i18n/es/actions.json';
import Errors from '@/assets/i18n/es/errors.json';
import Fields from '@/assets/i18n/es/fields.json';
import Home from '@/assets/i18n/es/home.json';
import Menu from '@/assets/i18n/es/menu.json';
import Validation from '@/assets/i18n/es/validation.json';
import Intro from '@/assets/i18n/es/intro.json';
import Pagination from '@/assets/i18n/es/pagination.json';
import Magazines from '@/assets/i18n/es/magazines.json';
import Date from '@/assets/i18n/es/date.json';
import Success from '@/assets/i18n/es/success.json';
import Campaigns from '@/assets/i18n/es/campaigns.json';
import Cookies from '@/assets/i18n/es/cookies.json';
import Card from '@/assets/i18n/es/card.json';
import Announcement from '@/assets/i18n/es/announcement.json';
import Events from '@/assets/i18n/es/events.json';
import Contents from '@/assets/i18n/es/contents.json';

export default {
    "actions": Actions,
    "errors": Errors,
    "fields": Fields,
    "home": Home,
    "menu": Menu,
    "validation": Validation,
    "intro": Intro,
    "pagination": Pagination,
    "magazines": Magazines,
    "date": Date,
    "success": Success,
    "campaigns": Campaigns,
    "cookies": Cookies,
    "card": Card,
    "announcement": Announcement,
    "events": Events,
    "contents": Contents
};
