import { render, staticRenderFns } from "./ThumbnailPost.vue?vue&type=template&id=45314605&scoped=true&"
import script from "./ThumbnailPost.vue?vue&type=script&lang=js&"
export * from "./ThumbnailPost.vue?vue&type=script&lang=js&"
import style0 from "./ThumbnailPost.vue?vue&type=style&index=0&id=45314605&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45314605",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VCard,VCol,VContainer,VHover,VImg,VOverlay,VRow,VSkeletonLoader,VSlideXTransition})
