import Cookies from 'js-cookie';

const STORAGE_NAME_ACCEPTED = 'cookies-accepted';
const STORATE_NAME_DECLINED = 'cookies-declined';

const cookies = {
    data() {
        return {
            isOpen: false,
        }
    },
    methods: {
        setAccepted() {
            Cookies.set(STORAGE_NAME_ACCEPTED, true, { expires: 365 });
        },
        accept() {
            this.setAccepted()
            this.isOpen = false
            this.$gtag.optIn();
        },
        setDeclined() {
            Cookies.set(STORATE_NAME_DECLINED, true, { expires: 365 });
        },

        decline() {
            this.setDeclined()
            this.isOpen = false
        },
        open() {
            if (!this.isAccepted) {
                this.isOpen = true
            }
        }
    },
    computed: {
        isAccepted() {
            return Cookies.get(STORAGE_NAME_ACCEPTED) === 'true';
        },
        isDeclined() {
            return Cookies.get(STORATE_NAME_DECLINED) === 'true';
        },
    },
    mounted() {
        if (!this.isAccepted && !this.isDeclined) {
            this.isOpen = true;
        }
    },
};

export default cookies;
