<template>
    <left-block :title="$t('home.pharmacy_strength')">
        <template v-slot:content>
            <v-row>
                <v-col>
                    <p v-html="$t('home.pharmacy_strength_content')"></p>
                </v-col>
            </v-row>
        </template>
        <template v-slot:img>
            <v-img
                src="@/assets/img/home-pharmacy/strength.jpg"
                contain
            ></v-img>
        </template>
    </left-block>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
