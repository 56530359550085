<template>
    <contents-layout
        :verticalBanners="verticalBanners"
        :billboardBanners="billboardBanners"
    >
        <contents-category-posts-component
            :posts="posts"
            :skeleton="skeleton"
            :category="category"
        />
        <v-pagination
            v-model="query.page"
            :length="query.totalPages"
        ></v-pagination>

    </contents-layout>
</template>

<script>
import blog from '@/mixins/blog'

export default {
    metaInfo () {
        return {
            title: `${this.$i18n.translate('menu.contents')} ${this.category.name ? this.category.name.toLowerCase() : ''}`
        }
    },
    data () {
        return {
            query: {
                perPage: 9,
                page: 1,
                totalPages: 1
            },
            posts: Array(6).fill({}),
            verticalBanners: [],
            billboardBanners: [],
            skeleton: true,
        }
    },
    watch: {
        query: {
            handler() {
                this.getPosts(this.query.page, this.query.perPage, [this.categoryId]).then(response => {
                    this.posts = []
                    this.posts = response.data
                })
            },
            deep: true
        }
    },
    mixins: [blog],
    mounted () {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi () {
            let promises = [
                this.getPosts(this.query.page, this.query.perPage, [this.categoryId]),
                this.getAdsCategory(this.categoryId)
            ]
            axios.all(promises).then((responses) => {
                this.posts = responses[0].data
                this.query.totalPages = parseInt(responses[0].headers['x-wp-totalpages'])
                this.billboardBanners = this.filterAdsBillboard(responses[1])
                this.verticalBanners = this.filterAdsVertical(responses[1])

                if (this.$route.params.title !== this.$options.filters.slug(this.category.name)) {
                    const route = this.$router.currentRoute
                    this.$router.push({
                        name: route.name,
                        params: {category: route.params.category, title: this.$options.filters.slug(this.category.name)}
                    })
                }
            }).catch(error => {
                this.axiosCatch(error)
            }).finally(() => {
                this.skeleton = false
            })
        }
    },
    computed: {
        categoryId () {
            return this.$route.params.category
        },
        category () {
            if (this.posts.length > 0 && this.posts[0]._embedded && this.posts[0]._embedded['wp:term']) {
                return this.posts[0]._embedded['wp:term'][0][0]
            }
            return {}
        },
    },
}
</script>

<style scoped>

</style>
