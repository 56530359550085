<template>
    <v-container
        :style="styles"
    >
        <v-row>
            <v-col>
                <v-skeleton-loader
                    v-if="skeleton"
                    type="heading"
                />
                <h4
                    v-else
                    class="text-uppercase text-h4 text-md-h6 red--text text--darken-3"
                    :class="[{bleeding: $vuetify.breakpoint.mdAndUp}]"
                >{{ category.name }}</h4>
            </v-col>
        </v-row>
        <v-row
            v-for="(post, i) in posts"
            dense
            :key="i"
        >
            <v-col>
                <v-divider v-if="i > 0"></v-divider>
                <v-card
                    :to="{name: 'contents.post', params:{post: post.id, title: $options.filters.slug(post.title.rendered)}}"
                    flat
                    class="mt-4 mb-2"
                >
                    <v-container fluid class="pa-0 ma-0">
                        <v-row>
                            <v-col cols="6" xl="5">
                                <v-skeleton-loader
                                    v-if="skeleton"
                                    type="image"
                                    max-height="96px"
                                />
                                <v-img
                                    v-else-if="post._embedded && post._embedded['wp:featuredmedia']"
                                    class="white--text align-end"
                                    :src="post._embedded['wp:featuredmedia'][0].source_url"
                                >
                                </v-img>
                            </v-col>
                            <v-col cols="6" xl="7">
                                <v-skeleton-loader
                                    v-if="skeleton"
                                    type="text@2"
                                />
                                <div v-else>
                                    <p
                                        class="mb-0"
                                        v-html="post.title.rendered"
                                    />
                                    <p class="wp-date">{{ post.date | date }}</p>
                                </div>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-center">
                <v-btn
                    v-if="category.id"
                    large dark color="xf"
                    :to="{ name: 'contents.category', params: { category: category.id, title: $options.filters.slug(category.name) } }"
                >
                    {{ $t('actions.see_more') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { convertToUnit } from 'vuetify/lib/util/helpers'

export default {
    props: {
        posts: {
            type: Array,
            default () {
                return []
            }
        },
        skeleton: {
            type: Boolean,
            default: false,
        },
        width: [Number, String],
    },
    computed: {
        category () {
            if (this.posts.length > 0 && this.posts[0]._embedded && this.posts[0]._embedded['wp:term']) {
                return this.posts[0]._embedded['wp:term'][0][0]
            }
            return {}
        },
        styles () {
            const styles = {}

            const width = convertToUnit(this.width)

            if (width) styles.width = width

            return styles
        },
    },
}
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.bleeding {
    margin-top: -6px;
    line-height: 1rem;
}
</style>
