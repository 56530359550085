<template>
    <v-hover>
        <template v-slot:default="{ hover }">
            <v-card
                tile
                flat
                :width="width"
                tag="article"
            >
                <v-skeleton-loader
                    v-if="showImage && skeleton"
                    type="image"
                />
                <template
                    v-else-if="showImage && post._embedded && post._embedded['wp:featuredmedia']"
                >
                    <maybe-link
                        :to="link"
                        :when="!!link"
                    >
                        <v-img
                            class="white--text align-end"
                            :aspect-ratio="2"
                            :src="post._embedded['wp:featuredmedia'][0].source_url"
                        >
                            <v-slide-x-transition
                                v-if="!showExcerpt && !skeleton && post.excerpt"
                            >
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    color="#d10a12"
                                    opacity="0.75"
                                >
                                    <p
                                        class="pa-4"
                                        v-html="post.excerpt.rendered"
                                    />
                                </v-overlay>
                            </v-slide-x-transition>
                        </v-img>
                    </maybe-link>
                </template>
                <v-container fluid class="pa-0 ma-0">
                    <v-row>
                        <v-col>
                            <v-skeleton-loader
                                v-if="showCategory && skeleton"
                                class="mt-2 mb-2"
                                width="25%"
                                type="text"
                            />
                            <v-container class="pa-0 ma-0"
                                         v-else-if="showCategory && post._embedded && post._embedded['wp:term'][0]">
                                <v-row no-gutters>
                                    <v-col>
                                        <p
                                            class="wp-category-title"
                                            v-html="post._embedded['wp:term'][0][0].name"
                                        />
                                    </v-col>
                                    <v-col>
                                        <p v-if="isPresentPost" class="text-right wp-date">{{ post.date | date }}</p>
                                    </v-col>
                                </v-row>
                            </v-container>

                            <v-skeleton-loader
                                v-if="showTitle && skeleton"
                                type="heading"
                                class="mb-2"
                                :class="{'mt-3': !showCategory}"
                            />
                            <header
                                v-else-if="showTitle && post.title"
                            >
                                <p v-if="!showCategory" class="mb-0 wp-date">{{ post.date | date }}</p>
                                <maybe-link
                                    class="post-link post-title"
                                    :to="link"
                                    :when="!!link"
                                >
                                    <h1
                                        class="text-h5"
                                        :class="{'mb-3': !showSubtitle || !post.subtitle}"
                                        v-html="post.title.rendered"
                                    />
                                    <p
                                        v-if="showSubtitle && post.subtitle"
                                        class="text-h6 mt-1 mb-3"
                                        v-html="post.subtitle"
                                    />
                                </maybe-link>
                            </header>

                            <template v-if="showExcerpt">
                                <v-skeleton-loader
                                    v-if="skeleton"
                                    type="text@3"
                                />
                                <template v-else>
                                    <div
                                        v-if="fullText && post.content"
                                        class="wordpress-post"
                                        v-html="post.content.rendered"
                                    />
                                    <p
                                        v-else-if="post.excerpt"
                                        class="grey--text text--darken-1 text-justify"
                                        v-html="post.excerpt.rendered"
                                    />

                                    <router-link
                                        class="post-link"
                                        :to="link"
                                        v-if="link"
                                    >{{ $t('contents.read_more') }}
                                    </router-link>
                                </template>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </template>
    </v-hover>
</template>

<script>
import MaybeLink from '@/components/contents/MaybeLink'
import blog from '../../mixins/blog'

export default {
    name: 'ThumbnailPost',
    components: {
        MaybeLink,
    },
    props: {
        post: Object,
        width: [Number, String],
        skeleton: {
            type: Boolean,
            default: false,
        },
        showImage: {
            type: Boolean,
            default: true
        },
        showCategory: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showExcerpt: {
            type: Boolean,
            default: true
        },
        readMoreLink: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [blog],
    computed: {
        link () {
            return this.post && !this.fullText && {
                name: 'contents.post',
                params: { post: this.post.id, title: this.$options.filters.slug(this.post.title.rendered) }
            }
        },
        fullText () {
            return this.post && this.post.format === 'short'
        },
        showSubtitle () {
            return this.fullText
        },
        isShort () {
            return this.post && this.post.format === 'short' && this.$vuetify.breakpoint.mdAndUp
        },
        isPresentPost () {
            return this.post &&
                this.post._embedded &&
                this.post._embedded['wp:term'] &&
                this.post._embedded['wp:term'][0] &&
                this.post._embedded['wp:term'][0].some(i => i.id === this.PRESENT_CATEGORY)
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/wordpress/_wp-variables.scss";

.post-title.post-link {
    text-decoration: none;
    color: inherit;
}

.post-link,
.post-link:hover {
    text-decoration: underline;
    color: $wp-vivid-red;
}
</style>
