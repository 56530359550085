<template>
    <v-container fluid class="px-0 py-12">
        <v-row>
            <v-col class="text-center">
                <h1>{{ $t('home.pharmacy_value') }}</h1>
            </v-col>
        </v-row>
        <header-divider-component></header-divider-component>
        <v-row>
            <v-col
                :key="index"
                v-for="(item, index) in items"
                md="6"
                lg="6"
                xl="3"
                cols="12"
                class="d-flex flex-column justify-center align-center"
            >
                <v-hover
                    v-slot="{ hover }"
                >
                    <v-card
                        class="ma-4"
                        @click="item.reveal = !item.reveal"
                        :elevation="hover ? 16 : 2"
                        max-height="400px"
                        max-width="400px"
                    >
                        <v-img
                            :src="item.img"
                            contain
                            max-height="400px"
                            max-width="400px"
                        ></v-img>
                        <v-fade-transition>
                            <v-card
                                v-if="item.reveal"
                                class="v-card--reveal"
                            >
                                <v-card-text class="text-center">
                                    <p class="display-1">{{ item.header }}</p>
                                    <p class="body-1">{{ item.content }}</p>
                                </v-card-text>
                            </v-card>
                        </v-fade-transition>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <v-row class="spacer">
            <v-col>
                &nbsp;
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            items: [],
        }
    },
    methods: {
        loadImages() {
            this.items = [
                {
                    header: this.$i18n.translate('home.pharmacy_value_header_1'),
                    content: this.$i18n.translate('home.pharmacy_value_content_1'),
                    img: require(`@/assets/img/home-pharmacy/${ this.locale }/icon-1.png`),
                    reveal: false,
                },
                {
                    header: this.$i18n.translate('home.pharmacy_value_header_2'),
                    content: this.$i18n.translate('home.pharmacy_value_content_2'),
                    img: require(`@/assets/img/home-pharmacy/${ this.locale }/icon-2.png`),
                    reveal: false,
                },
                {
                    header: this.$i18n.translate('home.pharmacy_value_header_3'),
                    content: this.$i18n.translate('home.pharmacy_value_content_3'),
                    img: require(`@/assets/img/home-pharmacy/${ this.locale }/icon-3.png`),
                    reveal: false,
                },
                {
                    header: this.$i18n.translate('home.pharmacy_value_header_4'),
                    content: this.$i18n.translate('home.pharmacy_value_content_4'),
                    img: require(`@/assets/img/home-pharmacy/${ this.locale }/icon-4.png`),
                    reveal: false,
                }
            ]
        }
    },
    watch: {
        locale() {
            this.loadImages();
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
    },
    mounted() {
        this.loadImages();
    }
}
</script>

<style scoped>
.v-card--reveal {
    top: 0;
    position: absolute;
    height: 100%;
}
</style>
