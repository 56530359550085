<template>
    <v-card
        class="pharmacy-card mx-auto mb-6"
        width="450px"
        elevation="16"
    >
        <v-toolbar color="xf" dark>
            <v-toolbar-title>{{ pharmacy.store_name }}</v-toolbar-title>
            <slot name="toolbar"></slot>
        </v-toolbar>

        <v-container fluid>
            <v-row no-gutters v-if="pharmacy.phone || pharmacy.store_email || pharmacy.mobile">
                <v-col>
                    <p v-if="pharmacy.phone">
                        <v-icon>mdi-phone</v-icon>
                        {{ pharmacy.phone }}
                    </p>
                    <p v-if="pharmacy.store_email">
                        <v-icon>mdi-email</v-icon>
                        {{ pharmacy.store_email }}
                    </p>
                    <p v-if="pharmacy.mobile">
                        <v-icon>mdi-whatsapp</v-icon>
                        {{ pharmacy.mobile }}
                    </p>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="pharmacy.street || pharmacy.zip || pharmacy.city">
                <v-col>
                    <p>
                        <v-icon>mdi-home</v-icon>
                        <span v-if="pharmacy.street">{{ pharmacy.street }}.</span>
                        {{ pharmacy.zip }}
                        {{ pharmacy.city }}
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    props: {
        pharmacy: {
            type: Object,
            default() {
                return {
                    store_name: null,
                    store_email: null,
                    phone: null,
                    mobile: null,
                    street: null,
                    zip: null,
                    city: null,
                }
            }
        }
    },
}
</script>

<style scoped>
.pharmacy-card {
    position: fixed;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
