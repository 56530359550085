<template>
    <customer-layout>
        <cookies-content-component></cookies-content-component>
    </customer-layout>
</template>

<script>
export default {
    metaInfo() {
        return {
            title: this.$i18n.translate('menu.cookies')
        }
    },
}
</script>

<style scoped>

</style>
