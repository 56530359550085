<template>
    <right-block :title="$t('home.pharmacy_map')" class="py-12">
        <template v-slot:content>
            <v-row>
                <v-col>
                    <p v-html="$t('home.pharmacy_map_content')"></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3">
                    <v-btn x-large
                           dark
                           outlined
                           block
                           class="xf mb-2"
                           @click="map"
                    >
                        {{ $t('actions.locator_pharmacies') }}
                    </v-btn>
                    <v-btn x-large
                           dark
                           block
                           color="#424242"
                           class="mt-2"
                           @click="contact"
                    >
                        {{ $t('actions.join_us_pharmacies') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-slot:img>
            <v-img
                src="@/assets/img/home/map.jpg"
                contain
            ></v-img>
        </template>
    </right-block>
</template>

<script>

export default {
    methods: {
        map() {
            this.$router.push({ name: 'pharmacy.map' });
        },
        contact() {
            this.$router.push({ name: 'pharmacy.contact' });
        }
    }
}
</script>

<style scoped>

</style>
