<template>
    <v-card
        class="services-external-block px-12 py-0"
        flat
        :color="color"
        tile
        min-width="500px"
    >
        <v-container fluid class="pa-0 ma-0">
            <v-row no-gutters align="start">
                <v-col cols="5" sm="6" md=8 lg="4" class="text-right pe-4">
                    <p class="white--text mt-2"><strong>{{ $t('actions.access') }}</strong></p>
                </v-col>
                <v-col cols="7" sm="6" md=4 lg="8">
                    <v-container fluid class="pa-0 ma-0">
                        <v-row
                            class="my-1"
                            v-for="(item, i) in items"
                            :key="i"
                            no-gutters
                        >
                            <v-col>
                                <v-btn
                                    class="px-2"
                                    :href="item.href"
                                    target="_blank"
                                    text
                                >
                                    <v-img
                                        contain
                                        :src="item.img"
                                        position="left center"
                                        :max-height="item.maxHeight"
                                        :max-width="item.maxWidth"
                                    ></v-img>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: 'xf'
        },
    },
    data() {
        return {
            defaultHeight: 25,
        }
    },
    computed: {
        items() {
            return [
                {
                    href: this.tvUrl,
                    img: require('@/assets/img/services/teamviewer.png'),
                    height: 118,
                    width: 517,
                    maxHeight: this.defaultHeight,
                    maxWidth: (25 * 517) / 118,
                },
                {
                    href: 'https://www.farmapremium.es/',
                    img: require('@/assets/img/services/farmapremium.png'),
                    height: 118,
                    width: 549,
                    maxHeight: this.defaultHeight,
                    maxWidth: (25 * 549) / 118
                },
                {
                    href: this.biUrl,
                    img: require('@/assets/img/services/bi.png'),
                    height: 118,
                    width: 724,
                    maxHeight: this.defaultHeight,
                    maxWidth: (25 * 724) / 118
                },
            ];
        }
    }
}
</script>

<style scoped>
.services-external-block {
    z-index: 2;
}

@media (min-width: 1264px) {
    .services-external-block {
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
