<template>
    <pharmacy-layout>
        <services-external-block color="grey"></services-external-block>
        <v-img
            position="bottom center"
            :src="banner"
        ></v-img>
        <v-container fluid class="services pa-0">
            <home-pharmacy-services-intro-block class="py-8"></home-pharmacy-services-intro-block>
            <home-pharmacy-services-block></home-pharmacy-services-block>
            <v-row class="grey lighten-3 mt-12 pb-12">
                <v-col class="py-10">
                    <v-row>
                        <v-col sm="6" class="px-12 pe-sm-3 pb-0">
                            <h1><strong>{{ $t('home.pharmacy_contact') }}</strong></h1>
                        </v-col>
                        <v-col class="px-12 pe-sm-3 pb-0">
                            <v-btn
                                class="text-none"
                                @click="contact"
                                text
                                x-large
                                v-html="$t('home.pharmacy_services_contact')"
                            ></v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col sm="6">
                            <header-divider-component></header-divider-component>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </pharmacy-layout>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: this.$i18n.translate('menu.services')
        }
    },
    methods: {
        contact() {
            this.$router.push({ name: 'pharmacy.contact' });
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
        banner() {
            return require(`@/assets/img/home-pharmacy/${ this.locale }/banner-1.jpg`);
        }
    }
}
</script>

<style scoped>

</style>
