<template>
    <v-container fluid class="pa-0 ma-0">
        <v-row>
            <v-col>
                <contents-ad-component type="vertical" :items="ads"></contents-ad-component>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-form ref="form" @submit.prevent="subscribe">
                    <h3>{{ $t('menu.newsletter') }}</h3>
                    <h4 class="mb-3" v-html="$t('menu.newsletter_details')"></h4>
                    <v-btn href="http://eepurl.com/gurI_v" target="_blank" dark large color="xf">{{ $t('actions.subscribe') }}</v-btn>
                </v-form>
            </v-col>
        </v-row>
        <v-row v-if="showMostReadPosts">
            <v-col>
                <p class="text-h4">{{ $t('menu.most_read') }}</p>
                <contents-thumbnail-posts-component
                    :posts="mostReadPosts"
                    :show-excerpt="false"
                ></contents-thumbnail-posts-component>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import rules from '../../mixins/rules'
import { mapGetters } from 'vuex'
import blog from '../../mixins/blog'

export default {
    props: {
        ads: {
            type: Array,
            default () {
                return []
            }
        },
        showMostReadPosts: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            email: null,
            subscribeTo: [],
            mostReadPosts: []
        }
    },
    mixins: [rules, blog],
    methods: {
        getDataFromApi () {
            if (this.showMostReadPosts) {
                this.$store.dispatch('loading/loading', true)
                this.getMostReadPosts().then(response => {
                    this.mostReadPosts = response.data
                }).catch(error => {
                    this.axiosCatch(error)
                }).finally(() => {
                    this.$store.dispatch('loading/loading', false)
                })
            }
        },
    },
    computed: {
        ...mapGetters({
            loading: 'loading/loading'
        }),
        ruleAtLeastOne () {
            return this.subscribeTo.length > 0 || this.$i18n.translate('validation.at_least_one', { 'attribute': '' })
        },
    },
    mounted () {
        this.getDataFromApi()
    },
}
</script>

<style scoped>

</style>
