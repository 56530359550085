<template>
    <v-card
        tile
        flat
        :width="width"
        tag="article"
    >
        <v-skeleton-loader
            v-if="skeleton"
            type="image"
        />
        <template
            v-else-if="post._embedded && post._embedded['wp:featuredmedia']"
        >
            <router-link
                :to="link"
            >
                <v-img
                    class="white--text align-end"
                    :aspect-ratio="2"
                    :src="post._embedded['wp:featuredmedia'][0].source_url"
                />
            </router-link>
        </template>
        <v-container fluid class="pa-0 ma-0">
            <v-row>
                <v-col>
                    <v-skeleton-loader
                        v-if="skeleton"
                        class="mt-2 mb-2"
                        width="25%"
                        type="text"
                    />
                    <v-container class="pa-0 ma-0"
                                 v-else-if="post._embedded && post._embedded['wp:term'][0]"
                    >
                        <v-row no-gutters>
                            <v-col>
                                <p
                                    class="wp-category-title"
                                    v-html="post._embedded['wp:term'][0][0].name"
                                />
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-skeleton-loader
                        v-if="skeleton"
                        type="heading"
                        class="mb-2"
                        :class="{'mt-3': !showCategory}"
                    />
                    <header
                        v-else-if="post.title"
                    >
                        <router-link
                            class="post-link post-title"
                            :to="link"
                        >
                            <h1
                                class="text-h5 mb-3"
                                v-html="post.title.rendered"
                            />
                        </router-link>
                    </header>

                    <v-skeleton-loader
                        v-if="skeleton"
                        type="text@3"
                    />
                    <router-link
                        class="post-link"
                        :to="link"
                        v-else-if="link"
                    >{{ $t('contents.read_more') }}
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import blog from '../../mixins/blog'

export default {
    name: 'ThumbnailShortPost',
    props: {
        post: Object,
        width: [Number, String],
        skeleton: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [blog],
    computed: {
        link () {
            return this.post && !this.fullText && {
                name: 'contents.post',
                params: { post: this.post.id, title: this.$options.filters.slug(this.post.title.rendered) }
            }
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/wordpress/_wp-variables.scss";

.post-title.post-link {
    text-decoration: none;
    color: inherit;
}

.post-link,
.post-link:hover {
    text-decoration: underline;
    color: $wp-vivid-red;
}
</style>
