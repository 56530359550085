<template>
    <v-row class="grey lighten-3">
        <v-col
            md="4"
            :key="index"
            v-for="(item, index) in items"
            class="px-12 py-10"
        >
            <home-customer-service-block :item="item"></home-customer-service-block>
        </v-col>
    </v-row>
</template>

<script>
import customer_services from "@/mixins/customer_services";

export default {
    mixins: [customer_services],
}
</script>

<style scoped>

</style>
