import Home from "@/pages/pharmacy/home";
import Services from "@/pages/pharmacy/services";
import Map from "@/pages/pharmacy/map";
import Contact from "@/pages/pharmacy/contact";
import Cookies from "@/pages/pharmacy/cookies";
import Announcement from "@/pages/pharmacy/announcement";

export default [
    {
        path: '/pharmacy/home',
        name: 'pharmacy.home',
        component: Home,
    },
    {
        path: '/pharmacy/services',
        name: 'pharmacy.services',
        component: Services,
    },
    {
        path: '/pharmacy/map',
        name: 'pharmacy.map',
        component: Map,
    },
    {
        path: '/pharmacy/contact',
        name: 'pharmacy.contact',
        component: Contact,
    },
    {
        path: '/pharmacy/cookies',
        name: 'pharmacy.cookies',
        component: Cookies,
    },
    {
        path: '/pharmacy/announcement',
        name: 'pharmacy.announcement',
        component: Announcement,
    },
];
