<template>
    <customer-layout>
        <v-img
            position="bottom center"
            :src="banner"
        ></v-img>
        <v-container fluid class="contact">
            <home-customer-contact-block></home-customer-contact-block>
        </v-container>
    </customer-layout>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: this.$i18n.translate('menu.contact')
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale();
        },
        banner() {
            return require(`@/assets/img/home/${ this.locale }/contact.jpg`);
        },
    },
}
</script>

<style scoped>

</style>
