import Opc from '@/pages/events/Opc'
import Verticalization from '@/pages/events/Verticalization'

export default [
    {
        path: '/events/opc',
        name: 'events.opc',
        component: Opc,
    },
    {
        path: '/events/verticalization',
        name: 'events.verticalization',
        component: Verticalization,
    },
];
