<template>
    <right-block
        :title="$t('card.earn')"
        classColRight="pe-md-12"
        classColLeft="ps-md-12"
    >
        <template v-slot:img>
            <v-img
                class="mx-9 mx-md-0 ml-md-auto"
                src="@/assets/img/card/icon-1.png"
                contain
                max-height="200px"
                max-width="200px"
            ></v-img>
        </template>
        <template v-slot:content>
            <v-row>
                <v-col>
                    <p v-html="$t('card.earn_content')"></p>
                </v-col>
            </v-row>
        </template>
    </right-block>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
