import Vue from 'vue';
import VueGtag from "vue-gtag";
import router from '@/router';
import cookies from "@/mixins/cookies";

let enabled = cookies.computed.isAccepted();
Vue.use(
    VueGtag,
    {
        config: { id: 'UA-22716244-1' },
        enabled: enabled
    },
    router
);
