<template>
    <v-row no-gutters>
        <v-col fluid class="mx-auto text-center">
            <v-carousel
                hide-delimiters
                cycle
                height="auto"
            >
                <v-carousel-item
                    v-for="(item, index) in banners"
                    :key="index"
                    :src="item.src"
                    cover
                    position="bottom center"
                    eager
                >
                    <a
                        v-if="item.href"
                        :href="item.href"
                        target="_blank"
                    >
                        <v-img :src="item.src" height="100%" eager/>
                    </a>
                    <v-img v-else :src="item.src" height="100%" eager/>
                </v-carousel-item>
            </v-carousel>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        banners: {
            type: Array,
            default () {
                return []
            }
        }
    }
}
</script>

<style scoped>

</style>
