import Vue from "vue";

Vue.filter('date', value => {
    if (value) {
        let date = moment(value);
        if (date.isValid()) {
            return date.format('DD/MM/YYYY');
        }
    }
    return null;
});

Vue.filter('datetime', value => {
    if (value) {
        let date = moment(value);
        if (date.isValid()) {
            return date.format('DD/MM/YYYY HH:mm:ss');
        }
    }
    return null;
});

Vue.filter('datedots', value => {
    if (value) {
        let date = moment(value);
        if (date.isValid()) {
            return date.format('DD.MM.YY');
        }
    }
    return null;
});

Vue.filter('datetext', value => {
    if (value) {
        let date = moment(value);
        if (date.isValid()) {
            let monthName = Vue.i18n.translate('date.month.' + (date.month() + 1));
            return date.format('DD') + ' ' + monthName + ' ' + date.format('Y');
        }
    }
    return null;
});

Vue.filter('month', value => {
    if (value) {
        let date = moment(value);
        if (date.isValid()) {
            return Vue.i18n.translate('date.month.' + (date.month() + 1));
        }
    }
    return null;
});

Vue.filter('dateformat', (value, format) => {
    // eslint-disable-next-line no-console
    console.log(value, format);
    if (value) {
        let date = moment(value);
        if (date.isValid()) {
            return date.format(format);
        }
    }
    return null;
});

Vue.filter('currency', value => {
    return number_format(value, 2, ',', '.') + ' €';
});

Vue.filter('temperature', value => {
    return number_format(value, 2, ',', '.') + ' ºC';
});

Vue.filter('discount', value => {
    return value + ' %';
});

Vue.filter('kg', value => {
    return value + ' kg';
});

Vue.filter('boolean', value => {
    // Return only name icon.
    if (value) {
        return 'check';
    } else {
        return 'close';
    }
});

Vue.filter('booleanColor', value => {
    if (value) {
        return 'success';
    } else {
        return 'error';
    }
});


Vue.filter('slug', value => {
    value = value.replace(/^\s+|\s+$/g, ''); // trim
    value = value.toLowerCase();

    // remove accents, swap ñ for n, etc
    let from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
        value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    value = value.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return value;
});

function number_format(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}
