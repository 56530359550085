<template>
    <pharmacy-layout :absolute="true" :footer="false">
        <map-component></map-component>
    </pharmacy-layout>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: this.$i18n.translate('menu.map')
        }
    },
}
</script>

<style scoped>

</style>
