<template>
    <v-fab-transition>
        <v-btn
            class="go-top-button"
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="grey"
            @click="toTop"
        >
            <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
    </v-fab-transition>
</template>

<script>
export default {
    data() {
        return {
            fab: null,
        }
    },
    methods: {
        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        },
        toTop() {
            this.$vuetify.goTo(0)
        }
    }
}
</script>

<style scoped>
.go-top-button {
    margin-bottom: 70px;
    margin-right: 6px;
}
</style>
