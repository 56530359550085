<template>
    <pharmacy-layout>
        <v-container>
            <v-row>
                <v-col class="mt-8">
                    <h1 class="display-1">
                        <strong v-html="$t('events.opc')"></strong>
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pt-0 mt-0">
                    <hr class="heading-divider">
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div v-html="$t('events.opc_content')"></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <event-fields-component
                        :shifts="shifts"
                        :parties="parties"
                        @confirmedAttendance="reloadShifts"
                    ></event-fields-component>
                </v-col>
            </v-row>
        </v-container>
    </pharmacy-layout>
</template>

<script>
const EVENT_OPC_ID = 1

export default {
    data () {
        return {
            parties: [],
            shifts: [],
        }
    },
    methods: {
        getDataFromApi () {
            this.$store.dispatch('loading/loading', true)

            let promises = []

            promises.push(
                this.getPharmacies(),
                this.getShifts(),
            )

            axios.all(promises).then((responses) => {
                this.parties = responses[0]
                this.shifts = responses[1]
            }).catch(error => {
                this.axiosCatch(error)
            }).finally(() => {
                this.$store.dispatch('loading/loading', false)
            })
        },
        reloadShifts () {
            this.$store.dispatch('loading/loading', true)
            this.shifts = []
            this.getShifts().then(response => {
                this.shifts = response
            }).catch(error => {
                this.axiosCatch(error)
            }).finally(() => {
                this.$store.dispatch('loading/loading', false)
            })
        },
        getPharmacies () {
            return axios.get(`${this.apiUrl}/api/public/pharmacies`).then((response) => {
                return response.data.data.map(i => {
                    return {
                        id: i.id,
                        text: i.name
                    }
                })
            }).catch((error) => {
                return Promise.reject(error)
            })
        },
        getShifts () {
            return axios.get(`${this.apiUrl}/api/public/events/${EVENT_OPC_ID}`).then((response) => {
                return response.data.data.shifts
            }).catch((error) => {
                return Promise.reject(error)
            })
        }
    },
    mounted () {
        this.getDataFromApi()
    }
}
</script>

<style scoped>

</style>
