<template>
    <v-card
        v-if="items.length > 0"
        flat
        :width="width"
        class="text-center mx-auto"
    >
        <p class="text-center text-caption my-0">{{ $t('fields.ad') }}</p>
        <v-fade-transition
            v-for="(item, i) in items"
            :key="i"
            hide-on-leave
        >
            <a :href="item.href" target="_blank">
                <v-img
                    v-show="current === i"
                    contain
                    :src="item.img"
                    :max-width="width"
                    :max-height="height"
                    eager
                ></v-img>
            </a>
        </v-fade-transition>
    </v-card>
</template>

<script>
const VERTICAL_HEIGHT = '600px'
const VERTICAL_WIDTH = '300px'
const BILLBOARD_HEIGHT = '250px'
const BILLBOARD_WIDTH = '970px'

export default {
    props: {
        items: {
            type: Array,
            default () {
                return []
            }
        },
        type: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            current: 0,
        }
    },
    computed: {
        width () {
            if (this.type === 'vertical') {
                return VERTICAL_WIDTH
            } else if (this.type === 'billboard') {
                return BILLBOARD_WIDTH
            }
            return 0
        },
        height () {
            if (this.type === 'vertical') {
                return VERTICAL_HEIGHT
            } else if (this.type === 'billboard') {
                return BILLBOARD_HEIGHT
            }
            return 0
        }
    },
    mounted () {
        setInterval(() => {
            this.current = (this.current + 1) % this.items.length
        }, 10000)
    }
}
</script>

<style scoped>

</style>
