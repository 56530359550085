<template>
    <v-card
        :to="{name: 'contents.post', params:{post: post.id, title: $options.filters.slug(post.title.rendered)}}"
        tile
        flat
        class="pa-0"
    >
        <v-container
            fluid
            class="pa-0 ma-0"
        >
            <v-row
                align="center"
                class="excerpt ma-0"
            >
                <v-col
                    cols="4"
                    class="white--text text-right"
                >
                    <p
                        v-if="showCategory && post._embedded && post._embedded['wp:term'][0]"
                        class="wp-category-title"
                        v-html="post._embedded['wp:term'][0][0].name"
                    />

                    <p
                        v-if="showTitle && post.title"
                        class="text-h4" v-html="post.title.rendered"
                    />
                    <p
                        v-if="showExcerpt && post.excerpt"
                        v-html="post.excerpt.rendered"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="8" offset="4"
                    xl="6" offset-xl="5"
                    class="pr-7"
                >
                    <v-img
                        v-if="showImage && post._embedded && post._embedded['wp:featuredmedia']"
                        class="white--text align-end"
                        :src="post._embedded['wp:featuredmedia'][0].source_url"
                    />
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: 'FullwidthExcerpt',
    props: {
        post: Object,
        showImage: {
            type: Boolean,
            default: true
        },
        showCategory: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showExcerpt: {
            type: Boolean,
            default: true
        },
    },
}
</script>

<style scoped>
.excerpt {
    background: url('/img/lineas.png') #6e0e00 20% 0 no-repeat;
    background-size: auto 100%;
    position: absolute;
    min-height: 337px;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

@media screen and (min-width: 1888px) {
    .excerpt {
        background-position: 35% 0;
    }
}
</style>
