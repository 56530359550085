<template>
    <customer-layout>
        <contents-menu-component></contents-menu-component>
        <router-view :key="$route.path"></router-view>
    </customer-layout>
</template>

<script>
import blog from '../../../mixins/blog'

export default {
    metaInfo(){
        return {
            title: this.$i18n.translate('menu.contents')
        }
    },
    data () {
        return {
            query: {
                perPage: 10,
                page: 1,
            },
            posts: [],
        }
    },
    mixins: [blog],
    beforeMount () {
        this.setLocale()
    },
    methods: {
        setLocale() {
            const locale = this.$route.params.locale;
            if (locale !== this.$i18n.locale()) {
                this.$i18n.set(locale)
            }
        }
    },
    watch: {
        blogLocale() {
            let route = this.$route
            if (route.params.locale !== this.blogLocale) {
                route.params.locale = this.blogLocale
                this.$router.push(route)
            }
        }
    },
    computed: {
        blogLocale() {
            return this.$i18n.locale()
        },
    },
}
</script>

<style scoped>

</style>
