<template>
    <div class="header">
        <v-app-bar
            height="90"
            class="px-md-12"
            flat
            :absolute="absolute"
        >
            <router-link :to="{name: 'welcome'}">
                <v-img src="@/assets/img/logos/xf.png"
                       max-width="50"
                       max-height="50"
                       contain
                ></v-img>
            </router-link>
            <div class="flex-grow-1"></div>
            <v-toolbar-items class="hidden-md-and-down" v-if="links.length > 0">
                <v-btn v-for="link in links"
                       :key="link.id"
                       text
                       color="xf"
                       :to="link.to"
                       class="subtitle-2"
                >
                    <strong>{{ $t(link.translate) }}</strong>
                </v-btn>
            </v-toolbar-items>
            <div class="flex-grow-1"></div>
            <div class="me-4 hidden-sm-and-down text-center">
                <v-btn
                    v-if="pharmacy"
                    target="_blank"
                    :href="apiUrl"
                    class="subtitle-2 me-4 hidden-sm-and-down"
                    color="grey"
                    dark
                    tile
                    elevation="0"
                    large
                >
                    <strong>{{ $t( 'menu.intranet' ) }}</strong>
                </v-btn>
            </div>
            <v-toolbar-items>
                <v-divider vertical></v-divider>
                <language-selector-component></language-selector-component>
            </v-toolbar-items>
            <v-menu offset-y>
                <template #activator="{ on }">
                    <v-app-bar-nav-icon class="hidden-lg-and-up" v-on="on"></v-app-bar-nav-icon>
                </template>
                <v-list v-if="links.length > 0">
                    <v-list-item v-for="link in links"
                                 :key="link.id"
                    >
                        <v-btn block
                               text
                               color="xf"
                               :to="link.to"
                        >
                            <strong>{{ $t(link.translate) }}</strong>
                        </v-btn>
                    </v-list-item>
                    <v-list-item v-if="pharmacy">
                        <v-btn
                            block
                            text
                            :href="apiUrl"
                            target="_blank"
                        >
                            <strong>{{ $t('menu.intranet') }}</strong>
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Array,
            default() {
                return []
            }
        },
        pharmacy: {
            type: Boolean,
            default: false,
        },
        absolute: {
            type: Boolean,
            default: false,
        }
    },
}
</script>

<style scoped>

</style>
