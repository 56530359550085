const utils = {
    methods: {
        decodeHtml(str) {
            return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
                return String.fromCharCode(charCode);
            });
        }
    },
};

export default utils;
