import Home from "./home";
import Customer from "./customer";
import Pharmacy from "./pharmacy";
import Events from "./events";

export default [
    ...Home,
    ...Customer,
    ...Pharmacy,
    ...Events
];
