<template>
    <customer-layout>
        <v-container fluid class="pa-0 ma-0">
            <v-img src="@/assets/img/home/card-header.jpg"></v-img>
            <card-free-block class="py-12"></card-free-block>
            <card-earn-block class="py-12"></card-earn-block>
            <card-discounts-block class="py-12"></card-discounts-block>
            <card-gifts-block class="py-12"></card-gifts-block>
            <card-info-block class="py-12"></card-info-block>
            <card-subscribe-block class="py-12"></card-subscribe-block>
        </v-container>
    </customer-layout>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: this.$i18n.translate('menu.card')
        }
    },
}
</script>

<style scoped>

</style>
