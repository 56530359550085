<template>
    <v-row>
        <v-col class="py-10">
            <v-row>
                <v-col sm="6" class="px-12 pe-sm-3 pb-0">
                    <h1 class="text-h5"><strong>{{ $t('home.contact') }}</strong></h1>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col sm="6">
                    <header-divider-component></header-divider-component>
                </v-col>
            </v-row>
            <v-form ref="formContact" @submit.prevent>
                <v-row>
                    <v-col cols="12" sm="6" class="px-12 pe-sm-3">
                        <v-text-field
                            v-model="contact.name"
                            :rules='[ruleRequired]'
                            required
                            :label="$t('fields.name')"
                        ></v-text-field>
                        <v-text-field
                            v-model="contact.surnames"
                            :label="$t('fields.surnames')"
                        ></v-text-field>
                        <v-text-field
                            v-model="contact.email"
                            :rules="[ruleRequired, ruleEmail]"
                            required
                            :label="$t('fields.email')"
                        ></v-text-field>
                        <v-text-field
                            v-model="contact.subject"
                            :label="$t('fields.subject')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-12 ps-sm-3">
                        <v-textarea
                            required
                            rows="9"
                            v-model="contact.msg"
                            :rules='[ruleRequired]'
                            outlined
                            :label="$t('fields.msg')"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-caption text-justify px-12">
                        <p> {{ $t('fields.contact_legal') }} </p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-right px-12 ps-sm-3">
                        <v-btn
                            :block="$vuetify.breakpoint.xsOnly"
                            dark
                            x-large
                            color="xf"
                            @click="contactSubmit"
                            :loading="loading"
                        >
                            {{ $t('actions.send') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import rules from "@/mixins/rules";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            contact: {
                name: null,
                surnames: null,
                email: null,
                subject: null,
                msg: null,
            }
        }
    },
    mixins: [rules],
    methods: {
        contactSubmit() {
            if (this.$refs.formContact.validate() && !this.loading) {
                this.$store.dispatch('loading/loading', true);
                axios.post(`${this.apiUrl}/api/public/contact/customer`, this.contact,
                ).then((response) => {
                    // eslint-disable-next-line no-console
                    console.log(response);
                    this.$refs.formContact.reset();
                    this.alert(this.$i18n.translate('success.sent'), 'success');
                }).catch(error => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    this.axiosCatch(error);
                }).finally(() => {
                    this.$store.dispatch('loading/loading', false);
                });
            }
        }
    },
    computed: mapGetters({
        loading: 'loading/loading',
    }),
}
</script>

<style scoped>

</style>
