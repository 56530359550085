const customer_services = {
    data() {
        return {
            magazines: null,
            campaigns: null,
        }
    },
    methods: {
        loadMagazines() {
            this.magazines = require.context('@/assets/img/magazines/', true, /01.jpg$/)
                .keys()
                .filter(i => i.replace('./', '').split('/')[0] === this.locale)
                .length;
        },
        loadCampaigns() {
            this.campaigns = require.context('@/assets/img/campaigns/', true, /index.png$/)
                .keys()
                .filter(i => i.replace('./', '').split('/')[2] === this.locale)
                .length;
        }
    },
    computed: {
        items() {
            return [
                {
                    img: require(`@/assets/img/home/${this.locale}/blog.jpg`),
                    title: this.$i18n.translate('home.services_blog'),
                    content: this.$i18n.translate('home.services_blog_content'),
                    to: { name: 'contents', params: { locale: this.locale } },
                },
                {
                    img: require('@/assets/img/home/magazines.jpg'),
                    title: this.$i18n.translate('home.services_magazines'),
                    content: this.$i18n.translate('home.services_magazines_content'),
                    to: { name: 'contents.magazines', params: { locale: this.locale } },
                    info: this.$i18n.translate('home.services_magazines_number', {number: this.magazines}),
                },
                {
                    img: require('@/assets/img/home/campaigns.jpg'),
                    title: this.$i18n.translate('home.services_campaigns'),
                    content: this.$i18n.translate('home.services_campaigns_content'),
                    to: { name: 'contents.diptychs', params: { locale: this.locale } },
                    info: this.$i18n.translate('home.services_campaigns_number', {number: this.campaigns}),
                }
            ]
        },
        locale() {
            return this.$i18n.locale();
        },
    },
    watch: {
        locale() {
            this.loadMagazines();
            this.loadCampaigns();
        }
    },
    mounted() {
        this.loadMagazines();
        this.loadCampaigns();
    }
};

export default customer_services;
