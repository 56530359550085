<template>
    <router-link
        v-if="when"
        :to="to"
        v-bind="$attrs"
    >
        <slot/>
    </router-link>
    <Fragment v-else>
        <slot/>
    </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'

export default {
    name: 'MaybeLink',
    components: {
        Fragment,
    },
    props: {
        to: [String, Object],
        when: Boolean,
    },
}
</script>
