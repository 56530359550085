<template>
    <v-row :class="'fill-height ' + colorFill" align="center">
        <v-col md="6" cols="12" :class="classColRight">
            <slot name="img"></slot>
        </v-col>
        <v-col md="6" cols="12" :class="classColLeft">
            <v-row>
                <v-col class="px-12 ps-md-3 text-justify">
                    <h1><strong>{{ title }}</strong></h1>
                </v-col>
            </v-row>
            <header-divider-component></header-divider-component>
            <v-row>
                <v-col class="px-12 ps-md-3 text-justify">
                    <slot name="content"></slot>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null,
        },
        colorFill: {
            type: String,
            default: 'grey lighten-3',
        },
        classColRight: {
            type: String,
            Default: null,
        },
        classColLeft: {
            type: String,
            Default: null,
        }
    }
}
</script>

<style scoped>

</style>
