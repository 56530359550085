const global = {
    data() {
        const isXarxa = window.location.hostname.search('xarxafarma') !== -1;
        return {
            apiUrl: process.env.VUE_APP_API,
            apiUrlOld: isXarxa ? 'https://www.xarxafarma.com' : 'https://www.neoxfarma.com',
            biUrl: isXarxa ? 'https://bi.xarxafarma.com' : 'https://bi.neoxfarma.com',
            tvUrl: isXarxa ? 'https://particulars.xarxafarma.com/software/teamviewer-xf.exe' : 'https://particulares.neoxfarma.com/software/teamviewer-neox.exe',
        }
    },
    methods: {
        alert(msg, type, details = null) {
            this.$store.dispatch('alert/push', {
                msg: msg,
                type: type,
                details: details
            });
        },
        axiosCatch(error) {
            // eslint-disable-next-line no-console
            console.log(error.response);
            this.alert(this.$i18n.translate('errors.error'), 'error', error.response.data.message);
        }
    },
};

export default global;
