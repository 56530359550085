<template>
    <div>
        <v-container fluid class="pa-0 ma-0">
            <v-row>
                <v-col v-on:wheel="smoothPickerWheel">
                    <smooth-picker
                        ref="smoothPicker"
                        :data="smoothPicker"
                        :change="filterMagazines"
                    ></smooth-picker>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    :key="magazine.id"
                    v-for="magazine in magazinesFiltered"
                >
                    <v-hover v-slot="{ hover }">
                        <v-card
                            @click="readMagazine(magazine.id)"
                            height="420px"
                            flat
                            class="text-center"
                        >
                            <v-card
                                width="250px"
                                class="mx-auto"
                                :elevation="hover ? 16 : 2"
                            >
                                <v-img
                                    width="250px"
                                    :src="magazine.src"
                                    contain
                                >
                                </v-img>
                            </v-card>
                            <v-card-text>
                                <div class="subtitle-1">
                                    {{ $t('magazines.period')[magazine.period] }}
                                </div>
                                <div>{{ $t('magazines.number') + ' ' + magazine.id }}</div>
                            </v-card-text>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </v-container>
        <v-overlay
            v-if="showFlipbook"
            :opacity="0.95"
        >
            <v-container fluid>
                <v-row>
                    <v-col>
                        <flipbook
                            class="flipbook"
                            ref="flipbook"
                            :pages="pages"
                            :startPage="pageNum"
                            v-slot="flipbook"
                            @flip-left-end="onFlipLeftEnd"
                            @flip-right-end="onFlipRightEnd"
                            :zooms="[1,2]"
                        >
                            <div class="text-center">
                                <v-btn
                                    icon
                                    :disabled="!flipbook.canFlipLeft"
                                    @click="flipbook.flipLeft"
                                >
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    :disabled="!flipbook.canZoomIn"
                                    @click="flipbook.zoomIn"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-select
                                    class="d-inline-flex px-4"
                                    :items="pagesSelect"
                                    v-model="pageNum"
                                ></v-select>
                                <v-btn
                                    icon
                                    :disabled="!flipbook.canZoomOut"
                                    @click="flipbook.zoomOut"
                                >
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    :disabled="!flipbook.canFlipRight"
                                    @click="flipbook.flipRight"
                                >
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                                <v-btn
                                    class="quit"
                                    icon
                                    @click="closeMagazine"
                                >
                                    <v-icon x-large>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </flipbook>
                    </v-col>
                </v-row>
            </v-container>
        </v-overlay>
    </div>
</template>

<script>
import { SmoothPicker } from 'vue-smooth-picker'

const START_YEAR = 2006
const START_PERIOD = 2
export default {
    components: {
        SmoothPicker
    },
    metaInfo(){
        return {
            title: this.$i18n.translate('menu.magazines')
        }
    },
    data () {
        return {
            magazine: this.$route.params.magazine,
            magazines: [],
            magazinesFiltered: [],
            showFlipbook: false,
            pages: [],
            pageNum: 1,
            smoothPicker: [
                {
                    currentIndex: 0,
                    list: [],
                    className: 'text-h6 font-weight-bold',
                }
            ],
            smoothPickerCurrentIndex: 0,
        }
    },
    methods: {
        readMagazine (magazine) {
            let files = require.context('@/assets/img/magazines/', true, /.jpg$/)
                .keys()
                .sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                }))
                .filter(i => {
                    let segments = i.replace('./', '').split('/')
                    return segments[0] === this.magazinesLocale && segments[1] === magazine
                })
                .map(i => {
                    return require(`@/assets/img/magazines/${i.replace('./', '')}`)
                })

            if (files.length > 0) {
                if (this.$route.params.magazine !== magazine) {
                    this.$router.push({ name: 'contents.magazines', params: { magazine: magazine } })
                }
                this.pages = [
                    null,
                    ...files,
                ]
                this.pageNum = 1
                this.showFlipbook = true
            }
        },
        closeMagazine () {
            this.$router.push({ name: 'contents.magazines' })
            this.showFlipbook = false
        },
        loadMagazines () {
            this.magazines = require.context('@/assets/img/magazines/', true, /01.jpg$/)
                .keys()
                .sort((a, b) => b.localeCompare(a, navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                }))
                .filter(i => i.replace('./', '').split('/')[0] === this.magazinesLocale)
                .map(i => {
                    let path = i.replace('./', '')
                    let id = path.split('/')[1]
                    return {
                        id: id,
                        year: this.getYear(id),
                        period: this.getPeriod(id),
                        src: require(`@/assets/img/magazines/${path}`),
                    }
                })
        },
        getYear (id) {
            return START_YEAR + Math.floor((START_PERIOD + parseInt(id)) / 6)
        },
        getPeriod (id) {
            return (START_PERIOD + parseInt(id)) % 6
        },
        filterMagazines (group, index) {
            let currentYear = null
            if (index) {
                currentYear = this.smoothPicker[0].list[index]
                this.smoothPickerCurrentIndex = index
            } else {
                currentYear = this.smoothPicker[0].list[this.smoothPicker[0].currentIndex]
            }

            this.magazinesFiltered = this.magazines.filter(i => i.year === currentYear)
        },
        setYearsPicker () {
            let lastYear = Math.max(...this.magazines.map(i => i.year))
            let firstYear = Math.min(...this.magazines.map(i => i.year))
            this.smoothPicker[0].list = [...Array((lastYear + 1) - firstYear)].map((_, index) => lastYear - index)
        },
        onFlipLeftEnd (page) {
            window.location.hash = '#' + page
        },
        onFlipRightEnd (page) {
            window.location.hash = '#' + page
        },
        setPageFromHash () {
            let n = parseInt(window.location.hash.slice(1))
            if (!isNaN(n)) {
                this.pageNum = n
            }
        },
        smoothPickerWheel (e) {
            let handle = false
            if (e.deltaY > 0 && this.smoothPickerCurrentIndex < this.smoothPicker[0].list.length - 1) {
                handle = true
                this.smoothPickerCurrentIndex++

            } else if (e.deltaY < 0 && this.smoothPickerCurrentIndex > 0) {
                handle = true
                this.smoothPickerCurrentIndex--
            }
            if (handle) {
                e.preventDefault()
                this.$refs.smoothPicker.setGroupData(0, {
                    currentIndex: this.smoothPickerCurrentIndex,
                    list: this.smoothPicker[0].list,
                    className: this.smoothPicker[0].className,
                })

                this.filterMagazines(0, this.smoothPickerCurrentIndex)
            }
        }
    },
    watch: {
        magazinesLocale () {
            this.loadMagazines()
            this.setYearsPicker()
            this.filterMagazines()
        }
    },
    computed: {
        magazinesLocale () {
            return this.$i18n.locale()
        },
        items () {
            return [
                {
                    img: require(`@/assets/img/home/${this.magazinesLocale}/blog.jpg`),
                    title: this.$i18n.translate('home.services_blog'),
                    content: this.$i18n.translate('home.services_blog_content'),
                    to: { name: 'blog' },
                },
                {
                    img: require('@/assets/img/home/campaigns.jpg'),
                    title: this.$i18n.translate('home.services_campaigns'),
                    content: this.$i18n.translate('home.services_campaigns_content'),
                    to: { name: 'campaigns' },
                    info: this.$i18n.translate('home.services_campaigns_number', { number: this.campaigns }),
                }
            ]
        },
        pagesSelect () {
            return [
                {
                    text: `${this.$i18n.translate('pagination.page')} 1`,
                    value: 1
                },
                ...Array.from({ length: this.pages.length / 2 }, (_, i) => {
                    let page = (i + 1) * 2
                    return {
                        text: `${this.$i18n.translate('pagination.pages')} ${page} - ${page + 1}`,
                        value: page,
                    }
                })
            ]
        },
        banner () {
            return require(`@/assets/img/home/${this.magazinesLocale}/banner-2.jpg`)
        },
    },
    mounted () {
        window.addEventListener('keydown', e => {
            switch (e.key) {
                case 'ArrowRight':
                    if (this.$refs.flipbook && this.$refs.flipbook.canFlipRight) {
                        this.$refs.flipbook.flipRight()
                    }
                    break
                case 'ArrowLeft':
                    if (this.$refs.flipbook && this.$refs.flipbook.canFlipLeft) {
                        this.$refs.flipbook.flipLeft()
                    }
                    break
                case 'Escape':
                    this.showFlipbook = false
                    break
            }
        })

        window.addEventListener('hashchange', () => {
            this.setPageFromHash()
        })

        this.loadMagazines()
        this.setYearsPicker()
        this.filterMagazines()
        if (this.magazine) {
            this.readMagazine(this.magazine)
        }

        this.setPageFromHash()
    }
}
</script>

<style scoped>
.flipbook {
    width: 90vw;
    height: 88vh;
}

.quit {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>
