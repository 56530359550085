<template>
    <v-app id="app">
        <alert-component></alert-component>
        <router-view></router-view>
        <v-overlay :value="loading">
            <loader-page-component></loader-page-component>
        </v-overlay>
    </v-app>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    metaInfo() {
        return {
            title: null,
            titleTemplate: '%s — XF',
            meta: [
                {vmid: 'description', name: 'description', content: this.metaDescription},
                {vmid: 'og:title', name: 'og:title', content: 'XF'},
                {vmid: 'og:description', name: 'og:description', content: this.metaDescription},
                {vmid: 'og:url', name: 'og:url', content: window.location.href},
                {vmid: 'og:image', name: 'og:image', content: this.metaImage},
                {vmid: 'og:type', name: 'og:type', content: 'article'},
                {vmid: 'og:locale', name: 'og:locale', content: this.metaLocale}
            ]
        }
    },
    computed: {
        ...mapGetters({
            loading: 'loading/loading'
        }),
        metaDescription() {
            return 'XarxaFarma'
        },
        metaImage() {
            return require(`@/assets/img/logos/xf.png`);
        },
        metaLocale() {
            return this.$i18n.locale() === 'ca' ? 'ca_ES' : 'es_ES'
        }
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/app.scss";
</style>
