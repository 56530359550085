<template>
    <v-row no-gutters class="pa-0 ma-0">
        <v-col>
            <hr class="heading-divider">
        </v-col>
    </v-row>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
