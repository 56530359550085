<template>
    <left-block :title="$t('home.pharmacy_services')">
        <template v-slot:content>
            <v-row>
                <v-col offset-lg="4" class="px-12 pe-md-3 text-justify text-md-right">
                    <p v-html="$t('home.pharmacy_services_content')"></p>
                </v-col>
            </v-row>
        </template>
        <template v-slot:img>
            <v-img
                src="@/assets/img/home-pharmacy/services.jpg"
                contain
            >
            </v-img>
        </template>
    </left-block>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
