import Actions from '@/assets/i18n/ca/actions.json';
import Errors from '@/assets/i18n/ca/errors.json';
import Fields from '@/assets/i18n/ca/fields.json';
import Home from '@/assets/i18n/ca/home.json';
import Menu from '@/assets/i18n/ca/menu.json';
import Validation from '@/assets/i18n/ca/validation.json';
import Intro from '@/assets/i18n/ca/intro.json';
import Pagination from '@/assets/i18n/ca/pagination.json';
import Magazines from '@/assets/i18n/ca/magazines.json';
import Date from '@/assets/i18n/ca/date.json';
import Success from '@/assets/i18n/ca/success.json';
import Campaigns from '@/assets/i18n/ca/campaigns.json';
import Cookies from '@/assets/i18n/ca/cookies.json';
import Card from '@/assets/i18n/ca/card.json';
import Announcement from '@/assets/i18n/ca/announcement.json';
import Events from '@/assets/i18n/ca/events.json';
import Contents from '@/assets/i18n/ca/contents.json';

export default {
    "actions": Actions,
    "errors": Errors,
    "fields": Fields,
    "home": Home,
    "menu": Menu,
    "validation": Validation,
    "intro": Intro,
    "pagination": Pagination,
    "magazines": Magazines,
    "date": Date,
    "success": Success,
    "campaigns": Campaigns,
    "cookies": Cookies,
    "card": Card,
    "announcement": Announcement,
    "events": Events,
    "contents": Contents,
};
