<template>
    <v-container>
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="skeleton" type="heading"></v-skeleton-loader>
                <h1 v-else class="text-h4 red--text text--darken-3 mb-4 text-uppercase">{{ category.name }}</h1>
            </v-col>
        </v-row>
        <v-row v-if="!skeleton && posts.length === 0">
            <v-col>
                <v-alert type="warning">{{ $t('errors.contents_empty_posts') }}</v-alert>
            </v-col>
        </v-row>

        <v-row>
            <template
                v-for="post in posts"
            >
                <v-col
                    cols="12"
                    md="6"
                    xl="4"
                    :key="post.id"
                >
                    <thumbnail-post
                        :skeleton="skeleton"
                        :post="post"
                        :show-category="false"
                        class="mb-6"
                    />
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import ThumbnailPost from '@/components/contents/ThumbnailPost'

export default {
    components: {
        ThumbnailPost,
    },
    props: {
        category: {
            type: Object,
            default () {
                return {}
            }
        },
        posts: {
            type: Array,
            default () {
                return []
            }
        },
        skeleton: {
            type: Boolean,
            default: false,
        }
    },
}
</script>
