<template>
    <div>
        <v-container fluid class="pa-0 ma-0 campaigns">
            <v-row>
                <v-col class="text-center pt-12">
                    <v-tooltip
                        bottom
                        v-for="category in categories"
                        :key="category.id"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-img
                                class="pointer d-inline-flex mx-4"
                                v-bind="attrs"
                                v-on="on"
                                contain
                                width="75px"
                                :src="(categorySelected == null || categorySelected === category.id) ? category.cover : category.coverGreyed"
                                @click="selectCategory(category.id)"
                            ></v-img>
                        </template>
                        <span>{{ $t(`campaigns.${category.id}`) }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-container
                        fluid
                        v-for="category in categoriesFiltered"
                        :key="category.id"
                        class="pb-12"
                    >
                        <v-row>
                            <v-col>
                                <v-container fluid class="px-0">
                                    <v-row align="center">
                                        <v-col
                                            cols="4"
                                            sm="3"
                                            md="2"
                                            xl="1"
                                        >
                                            <v-img
                                                contain
                                                width="100px"
                                                :src="category.coverXF"
                                            ></v-img>
                                        </v-col>
                                        <v-col
                                            cols="8"
                                            sm="9"
                                            md="10"
                                            xl="11"
                                        >
                                            <h1>{{ $t(`campaigns.${category.id}`) }}</h1>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="6"
                                md="3"
                                v-for="campaign in category.campaigns"
                                :key="campaign.id"
                            >
                                <v-hover v-slot="{ hover }">
                                    <v-card
                                        @click="readCampaign(campaign)"
                                        :elevation="hover ? 16 : 2"
                                        class="text-center"
                                        width="250px"
                                    >
                                        <v-img
                                            contain
                                            width="250px"
                                            :src="campaign.cover"
                                            class="mx-auto"
                                        ></v-img>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
            v-model="dialogReadCampaign"
            max-width="800"
        >
            <v-card
                v-if="campaign"
                max-width="800"
            >
                <v-container fluid>
                    <v-row align="center">
                        <v-col>
                            <v-img
                                class="mx-auto"
                                contain
                                :src="campaign.cover"
                                width="300"
                            ></v-img>
                        </v-col>
                        <v-col>
                            <p
                                :key="file.filename"
                                v-for="file in campaign.files"
                            >
                                <v-btn
                                    text
                                    :href="file.href"
                                    target="_blank"
                                >
                                    <v-icon color="xf">mdi-file-pdf</v-icon>
                                    {{ file.filename }}
                                </v-btn>
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    metaInfo(){
        return {
            title: this.$i18n.translate('menu.diptychs')
        }
    },
    data () {
        return {
            dialogReadCampaign: false,
            campaign: null,
            campaigns: [],
            categorySelected: null,
            categories: [],
        }
    },
    methods: {
        loadCampaigns () {
            let files = require.context('@/assets/img/campaigns/', true, /.pdf$/)
                .keys()
                .sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                }))
                .filter(i => i.replace('./', '').split('/')[2] === this.campaignsLocale)
                .map(i => {
                    return {
                        campaign: i.replace('./', '').split('/')[1],
                        category: i.replace('./', '').split('/')[0],
                        filename: i.replace('./', '').split('/')[3].replace('.pdf', ''),
                        href: require(`@/assets/img/campaigns/${i.replace('./', '')}`)
                    }
                })

            this.campaigns = require.context('@/assets/img/campaigns/', true, /index.png$/)
                .keys()
                .sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                }))
                .filter(i => i.replace('./', '').split('/')[2] === this.campaignsLocale)
                .map(i => {
                    return {
                        id: i.replace('./', '').split('/')[1],
                        category: i.replace('./', '').split('/')[0],
                        cover: require(`@/assets/img/campaigns/${i.replace('./', '')}`),
                        files: files.filter(f => f.campaign === i.replace('./', '').split('/')[1] && f.category === i.replace('./', '').split('/')[0]),
                    }
                })

            this.categories = require.context('@/assets/img/campaigns/', true, /cover.png$/)
                .keys()
                .sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                }))
                .map(i => {
                    return {
                        id: i.replace('./', '').split('/')[0],
                        cover: require(`@/assets/img/campaigns/${i.replace('./', '')}`),
                        coverGreyed: require(`@/assets/img/campaigns/${i.replace('./', '').replace('cover.png', 'cover-greyed.png')}`),
                        coverXF: require(`@/assets/img/campaigns/${i.replace('./', '').replace('cover.png', 'cover-xf.png')}`),
                        campaigns: this.campaigns.filter(c => c.category === i.replace('./', '').split('/')[0]),
                    }
                })
        },
        selectCategory (id) {
            if (this.categorySelected === id) {
                this.categorySelected = null
            } else {
                this.categorySelected = id
            }
        },
        readCampaign (campaign) {
            this.campaign = campaign
            this.dialogReadCampaign = true
        },
    },
    watch: {
        campaignsLocale () {
            this.loadCampaigns()
        }
    },
    computed: {
        campaignsLocale () {
            return this.$i18n.locale()
        },
        categoriesFiltered () {
            return this.categories.filter(i => this.categorySelected == null || this.categorySelected === i.id)
        },
        items () {
            return [
                {
                    img: require(`@/assets/img/home/${this.locale}/blog.jpg`),
                    title: this.$i18n.translate('home.services_blog'),
                    content: this.$i18n.translate('home.services_blog_content'),
                    to: { name: 'blog' },
                },
                {
                    img: require('@/assets/img/home/magazines.jpg'),
                    title: this.$i18n.translate('home.services_magazines'),
                    content: this.$i18n.translate('home.services_magazines_content'),
                    to: { name: 'magazines' },
                    info: this.$i18n.translate('home.services_magazines_number', { number: this.magazines }),
                },
            ]
        },
        banner () {
            return require(`@/assets/img/home/${this.campaignsLocale}/banner-3.jpg`)
        },
    },
    mounted () {
        this.loadCampaigns()
    }
}
</script>

<style scoped>

</style>
