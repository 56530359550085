<template>
    <v-row no-gutters>
        <v-col>
            <v-container class="px-0 pt-0 pb-12 ma-0" fluid>
                <v-row>
                    <v-col class="text-md-center">
                        <h1 class="px-12"><strong>{{ $t('card.subscribe') }}</strong></h1>
                        <header-divider-component></header-divider-component>
                    </v-col>
                </v-row>
                <v-row class="grey lighten-3">
                    <v-col
                        v-for="(item, i) in items"
                        :key="i"
                        class="px-12 py-6"
                        cols="12"
                        md="6"
                    >
                        <v-container fluid class="pa-0 ma-0 text-center">
                            <v-row no-gutters>
                                <v-col>
                                    <v-card
                                        elevation="16"
                                        tile
                                    >
                                        <v-container class="px-12 py-12">
                                            <v-row>
                                                <v-col>
                                                    <v-img
                                                        class="mx-auto"
                                                        :src="item.img"
                                                        contain
                                                        max-height="200px"
                                                        max-width="200px"
                                                    ></v-img>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <h1>{{ item.title }}</h1>
                                                    <header-divider-component></header-divider-component>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <p>{{ item.content }}</p>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        dark
                                        class="xf"
                                        x-large
                                        :to="item.to"
                                        :href="item.href"
                                        :target="item.target"
                                    >
                                        {{ item.action }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    img: require("@/assets/img/card/icon-4.png"),
                    title: this.$i18n.translate('card.subscribe_home'),
                    content: this.$i18n.translate('card.subscribe_home_content'),
                    action: this.$i18n.translate('actions.signup'),
                    href: 'https://www.farmapremium.es/registrate',
                    target: '_blank',
                },
                {
                    img: require("@/assets/img/card/icon-5.png"),
                    title: this.$i18n.translate('card.subscribe_pharmacy'),
                    content: this.$i18n.translate('card.subscribe_pharmacy_content'),
                    action: this.$i18n.translate('actions.localize_pharmacy'),
                    to: { name: 'map' },
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>
