<template>
    <div>
        <gmap-map
            ref="gmap"
            :options="gmapsOptions"
            :center="center"
            :zoom="zoom"
            style="width:100vw;  height: 100vh"
        >
            <gmap-cluster :styles="clusterStyles">
                <gmap-marker
                    :key="pharmacy.index"
                    v-for="pharmacy in pharmacies"
                    :position="pharmacy.position"
                    :icon="{ url: require('@/assets/img/gmaps/marker.png')}"
                    :animation="pharmacy.animation"
                    @click="selectMarker(pharmacy)"
                >
                </gmap-marker>
            </gmap-cluster>
        </gmap-map>
        <v-fab-transition>
            <v-btn
                v-if="!searchVisible"
                fab
                dark
                class="btn_search"
                @click="searchVisible = !searchVisible"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </v-fab-transition>
        <v-fade-transition>
            <v-card
                v-if="searchVisible"
                class="search"
                dark
                tile
            >
                <v-card-text>
                    <v-form
                        ref="formContact"
                        @submit.prevent
                        @submit="geocode"
                    >
                        <v-text-field
                            v-model="search"
                            dark
                            filled
                            outlined
                            :label="$t('fields.search_pharmacy_by_city')"
                            append-icon="mdi-chevron-right"
                            append-outer-icon="mdi-close"
                            prepend-icon="mdi-magnify"
                            @click:append="geocode"
                            @click:prepend="searchVisible = !searchVisible"
                            @click:append-outer="searchVisible = !searchVisible"
                            hide-details
                        ></v-text-field>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-fade-transition>
        <pharmacy-card-component v-if="pharmacy" :pharmacy="pharmacy">
            <template slot="toolbar">
                <v-spacer></v-spacer>

                <v-btn icon @click="resetPharmacySelected">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </pharmacy-card-component>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchVisible: true,
            search: null,
            clusterStyles: [
                {
                    height: 53,
                    width: 53,
                    textColor: '#000000',
                    textSize: 12,
                    url: require('@/assets/img/gmaps/m1.png'),
                },
                {
                    height: 56,
                    width: 56,
                    textColor: '#000000',
                    textSize: 12,
                    url: require('@/assets/img/gmaps/m2.png'),
                },
                {
                    height: 66,
                    width: 66,
                    textColor: '#000000',
                    textSize: 12,
                    url: require('@/assets/img/gmaps/m3.png'),
                }
            ],
            gmapsOptions: {
                streetViewControl: true,
                scaleControl: true,
                mapTypeControl: true,
            },
            center: {lat: 40.820705, lng: -2.623358}, // Default view spain
            zoom: 6,
            pharmacies: [],
            pharmacy: null,
            gmapsKey: process.env.VUE_APP_GMAPS_KEY,
            gmapsApiUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
        };
    },
    methods: {
        geolocate() {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                this.zoom = 16;
            });
        },
        geocode() {
            this.$store.dispatch('loading/loading', true);
            axios.get(`${this.gmapsApiUrl}?address=${this.search}&region=es&components=country:ES&key=${this.gmapsKey}`).then(response => {
                // eslint-disable-next-line no-console
                console.log(response);
                if (response.data.results.length > 0) {
                    this.center = response.data.results[0].geometry.location;
                    this.zoom = 16;
                } else {
                    this.alert(this.$i18n.translate('errors.error'), 'error');
                }
            }).catch(error => {
                this.axiosCatch(error);
            }).finally(() => {
                this.$store.dispatch('loading/loading', false);
            });
        },
        getPharmacies() {
            this.$store.dispatch('loading/loading', true);
            axios.get(`${this.apiUrl}/api/public/pharmacies`).then((response) => {
                // eslint-disable-next-line no-console
                console.log(response.data.data);
                this.pharmacies = response.data.data.filter(item => {
                    if (item.addresses.length > 0) {
                        if (item.addresses[0].latitude && item.addresses[0].longitude) {
                            return true;
                        } else {
                            // eslint-disable-next-line no-console
                            console.log('Pharmacy without geolocation!', item.code);
                        }
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('Pharmacy without address!', item.code);
                    }
                    return false;
                }).map((item) => {
                    let address = item.addresses[0];
                    return {
                        ...item,
                        city: address.city,
                        province: address.province.name,
                        street: address.street,
                        zip: address.zip,
                        position: {
                            lat: parseFloat(address.latitude),
                            lng: parseFloat(address.longitude)
                        },
                        animation: 0,
                    };
                });
            }).catch((error) => {
                this.axiosCatch(error);
            }).finally(() => {
                this.$store.dispatch('loading/loading', false);
            })
        },
        selectMarker(pharmacy) {
            this.resetPharmacySelected();
            this.pharmacy = this.pharmacies.filter((item) => {
                return item.id === pharmacy.id;
            })[0];

            this.center = {
                lat: this.pharmacy.position.lat,
                lng: this.pharmacy.position.lng,
            };

            this.$refs.gmap.$mapPromise.then((map) => {
                map.setZoom(16);
            });

            this.pharmacy.animation = 1;
        },
        resetPharmacySelected() {
            this.resetMarkersAnimation();
            this.pharmacy = null;
        },
        resetMarkersAnimation() {
            this.pharmacies.forEach((item) => {
                item.animation = 0;
            });
        },
    },
    mounted() {
        this.geolocate();
        this.getPharmacies();
    },
}
</script>

<style scoped>
.btn_search {
    position: fixed;
    transform: translate(-50%, -50%);
}

.search {
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
}

@media (min-width: 800px) {
    .btn_search {
        top: 18%;
        left: 5%;
    }

    .search {
        top: 18%;
        width: 60vw;
    }
}

@media (max-width: 800px) {
    .btn_search {
        top: 19%;
        left: 15%;
    }

    .search {
        top: 19%;
        width: 100vw;
    }
}

</style>
