<template>
    <pharmacy-layout>
        <v-container class="py-6">
            <v-row>
                <v-col>
                    <div v-html="$t('announcement.content')"></div>
                </v-col>
            </v-row>
        </v-container>
    </pharmacy-layout>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
