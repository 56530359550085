<template>
    <v-row>
        <v-col class="py-10">
            <v-row>
                <v-col sm="6" class="px-12 pe-sm-3 pb-0">
                    <h1><strong>{{ $t('home.pharmacy_dates') }}</strong></h1>
                </v-col>
            </v-row>
            <v-row class="mt-0">
                <v-col sm="6">
                    <header-divider-component></header-divider-component>
                </v-col>
            </v-row>
            <v-row class="px-12">
                <v-col offset-md="6" md="6">
                    <v-sheet
                        tile
                        height="54"
                        class="d-flex"
                    >
                        <v-btn
                            icon
                            class="ma-2"
                            @click="$refs.calendar.prev()"
                        >
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            class="ma-2"
                            @click="$refs.calendar.next()"
                        >
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>

                    </v-sheet>
                    <v-sheet height="300">
                        <v-calendar
                            ref="calendar"
                            type="month"
                            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                            v-model="calendar"
                        ></v-calendar>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            calendar: null,
        }
    },
}
</script>

<style scoped>

</style>
