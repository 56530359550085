import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import es from 'vuetify/es5/locale/es';
import ca from 'vuetify/es5/locale/ca';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: colors.grey.darken4,
                xf: colors.red.darken3,
                alternative: colors.teal.darken2,
            },
        },
    },
    lang: {
        locales: {es, ca},
        current: Vue.i18n.locale(),
    },
})
