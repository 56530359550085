<template>
    <v-container class="py-6">
        <v-row>
            <v-col>
                <div v-html="$t('cookies.content')"></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
