<template>
    <left-block
        :title="$t('card.discounts')"
        classColRight="pe-md-12"
        classColLeft="ps-md-12"
    >
        <template v-slot:content>
            <v-row>
                <v-col>
                    <p v-html="$t('card.discounts_content')"></p>
                </v-col>
            </v-row>
        </template>
        <template v-slot:img>
            <v-row>
                <v-col class="px-12 ps-md-3">
                    <v-img
                        src="@/assets/img/card/icon-2.png"
                        contain
                        max-width="200px"
                        max-height="200px"
                    ></v-img>
                </v-col>
            </v-row>
        </template>
    </left-block>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
