<template>
    <v-container>
        <v-row>
            <v-col>
                <slot name="billboard">
                    <contents-ad-component type="billboard" :items="billboardBanners"></contents-ad-component>
                </slot>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="9">
                <slot></slot>
            </v-col>
            <v-col cols="12" md="3">
                <contents-search-component
                    class="hidden-md-and-up mx-auto"
                />
                <contents-lateral-component
                    :ads="verticalBanners"
                    :show-most-read-posts="showMostReadPosts"
                />
            </v-col>
        </v-row>
        <slot name="after-content"></slot>
    </v-container>
</template>

<script>
export default {
    props: {
        verticalBanners: {
            type: Array,
            default () {
                return []
            }
        },
        billboardBanners: {
            type: Array,
            default () {
                return []
            }
        },
        showMostReadPosts: {
            type: Boolean,
            default: true,
        },
    }
}
</script>

<style scoped>

</style>
