<template>
    <customer-layout>
        <v-container fluid class="home pa-0">
            <banner-block :banners="banners"></banner-block>
            <home-customer-commited-block></home-customer-commited-block>
            <home-customer-card-block></home-customer-card-block>
            <home-customer-map-block></home-customer-map-block>
            <home-customer-services-block></home-customer-services-block>
            <home-customer-contact-block></home-customer-contact-block>
        </v-container>
    </customer-layout>
</template>

<script>
import blog from '@/mixins/blog'

export default {
    metaInfo () {
        return {
            title: `${this.$i18n.translate('menu.home')} ${this.$i18n.translate('menu.customer').toLowerCase()}`
        }
    },
    data () {
        return {
            ads: [],
            sliders: {
                ca: [],
                es: [],
            }
        }
    },
    mixins: [blog],
    methods: {
        getDataFromApi () {
            this.getAdsHome().then((response) => {
                this.ads = this.filterAdsNetboard(response)
            }).catch(error => {
                this.axiosCatch(error)
            })
        }
    },
    watch: {
        blogLocale () {
            this.getDataFromApi()
        },
    },
    computed: {
        locale () {
            return this.$i18n.locale()
        },
        banners () {
            let images = []
            this.sliders[this.locale].map(i => {
                let image = { src: require(`@/assets/img/sliders/customer/${this.locale}/${i}`) }
                try {
                    const extra = require(`@/assets/img/sliders/customer/${this.locale}/${i}.json`)
                    image = { ...image, ...extra }
                } catch (ex) {
                    // eslint-disable-next-line
                    console.log(ex)
                }
                images.push(image)
            })
            this.ads.map(({ img, href }) => {
                images.push({
                    src: img,
                    href,
                })
            })
            return images
        },
    },
    created () {
        this.getDataFromApi()
        require.context('@/assets/img/sliders/customer/', true, /(.*)(gif|jpg|jpeg|tiff|png|json)$/).keys().forEach(i => {
            if (i.endsWith('.json')) return
            let segments = i.replace('./', '').split('/')
            this.sliders[segments[0]].push(segments[1])
        })
    }
}
</script>

<style scoped>

</style>
